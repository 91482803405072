import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';

class LoginVerification extends Component {
   constructor(props) {
     super(props);
     this.timer = 0;
     this.state = {
        isOtpInputShow : false,
        aadhaarRequestId: null,
        timeCounter: 60,
    };
   }

    sendOtp = async (e) => {
        e && e.preventDefault();
        let { setLoader, setError } = this.props;
        setLoader(true);
        try{
            var mobile = document.getElementById("mobile_no").value;
            // var aadhaar_no = document.getElementById("aadhaar_no").value;
            let resp = await auth.MyTeam.sendOtp({mobile, feature: "MT"});
            // let resp = await auth.MyTeam.sendMyTeamOtp({mobile, aadhaar_no});
            this.setState({aadhaarRequestId: resp.data.data.request_id, isOtpInputShow : true});
            this.timer = setInterval(this.countDown, 1000);
            // setError({showBar: true, message: "OTP sent to Mobile", isSuccess: true})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    submitOtp = async (e) => {
        e && e.preventDefault();
        let { setLoader, setError, teamCode } = this.props;
        let { aadhaarRequestId } = this.state;
        setLoader(true);
        try{
            var name = document.getElementById("user_name").value;
            // var aadhaar_no = document.getElementById("aadhaar_no").value;
            var mobile = document.getElementById("mobile_no").value;
            var otp = document.getElementById("otp_input_myTeam").value;
            
            await auth.MyTeam.loginWithOTP({request_id: aadhaarRequestId, team_code: teamCode, first_name: name, mobile, otp});
            this.props.getUser();
            this.setState({isOtpInputShow : false});
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }



    countDown = () => {
        let timeCounter = this.state.timeCounter ? this.state.timeCounter - 1 : 59;
        if (timeCounter == 0) clearInterval(this.timer);
        this.setState({ timeCounter });
    }

    render() {
        let {isOtpInputShow, timeCounter} = this.state;
        let { teamData } = this.props;
        return (
  
            <form>
                <Card className="w-100" style={{borderRadius: 8, borderColor: "#eee", background:"#fff url('/Image/tl_background.png') top no-repeat", backgroundSize: "contain"}}>
                    <CardBody className="px-3 py-2 text-left" >
                        <div className='text-center my-3'>
                            <img src={teamData && teamData.profile_picture_url ? teamData.profile_picture_url : UserSVG} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} />
                            <h3 style={{color: "#377c7b", fontWeight: 700}}>{teamData && teamData.User.first_name}</h3>
                            <h5>Your Team Leader</h5>
                        </div>


                        <div className='shadow-sm m-2 bg-light p-3' style={{borderRadius: 12}}>
                            <form onSubmit={this.sendOtp}>
                                <strong className='d-block mb-3 text-muted' style={{fontSize: 15}}>Login</strong>
                                <strong className='d-block my-2 text-muted' style={{fontSize: 12}}>Your Name</strong>
                                <InputFeild minLength={3} required id="user_name" name="user_name" placeholder="Enter Your Name" />
                                {/* <strong className='d-block my-2 text-muted' style={{fontSize: 12}}>Aadhaar Number</strong>
                                <InputFeild minLength={12} required id="aadhaar_no" name="aadhaar_no" placeholder="Enter your Aadhaar number for verification" /> */}
                                <strong className='d-block my-2 text-muted' style={{fontSize: 12}}>Mobile Number</strong>
                                <InputFeild id="mobile_no" required minLength={10} maxLength={10} pattern="\d*" name="user_mobile" placeholder="Enter your number" />
                                <Button className="mb-3 mt-4 w-100" type="submit" color="dark" style={{borderRadius: 20}} >Get OTP to login</Button>
                            </form>
                        </div>
                       
                       <img src="/Image/tl_banner.png" style={{width: "100%"}} className="p-2" />
                       
                        <Modal centered isOpen={!!isOtpInputShow}>
                            <ModalBody className="p-5">
                                <form onSubmit={this.submitOtp}>
                                    <strong className='d-block text-muted text-center my-2' style={{fontSize: 15}}>Enter OTP</strong>
                                    {/* <strong className='d-block text-muted text-center my-2' style={{fontSize: 12}}>Sent on your Aadhaar registered mobile number</strong> */}
                                    <InputFeild type="number" required id="otp_input_myTeam" className="mt-4" name="otp" placeholder="Enter 4 Digit OTP received on mobile" />
                                    
                                    {timeCounter > 0 ? 
                                        <strong className='d-block mt-3 text-muted text-center' style={{fontSize: 12}}>OTP will expire in <strong style={{fontSize: 12, color: "#e3b705"}}>{timeCounter}</strong> sec , <strong color="link" className="mt-1" style={{color: "#f16868", fontSize: 10 , cursor: "pointer"}} onClick={() => {clearInterval(this.timer); this.setState({isOtpInputShow: false});  } }> Edit Details</strong>
                                        </strong>
                                        :
                                        <strong className='d-block mt-3 text-muted text-center' style={{fontSize: 12}}>Didn't received OTP <strong onClick={() => this.sendOtp()} style={{fontSize: 12, color: "#e3b705", cursor: "pointer"}}>Send Again , </strong>  <strong color="link" className="" style={{color: "#f16868" , fontSize: 10 , cursor: "pointer"}} onClick={() => {clearInterval(this.timer); this.setState({isOtpInputShow: false});  } }> Edit Details</strong>
                                        </strong>
                                    }
                                    <Button className="mb-3 mt-4 w-100" type="submit" color="dark" style={{borderRadius: 20}} >Verify OTP to continue</Button>

                                    {/* <Button size="sm" color="link" className="small" style={{color: "#f16868"}} onClick={() => {clearInterval(this.timer); this.setState({isOtpInputShow: false});  } }>Edit Details</Button> */}
                                </form>
                        </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
            </form>

        );
        }
}

export default LoginVerification;
