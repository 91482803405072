
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { copyCodeToClipboard, downloadApiFile } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';

class PartnerProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobList: [],
            isShowJobLink: false,
            searchText: ""
        };
    }


    componentDidMount() {
        this.fetchJobs();
      
    }


   

    fetchJobs = async () => {
        let { } = this.props;
        store.dispatch(setLoader(true));
        try {

            let resp = await Auth.Partner.fetchJobs({job_category_id: 6});
            let jobList = resp.data.data.filter(job => parseInt(job.employer_id) !== 120); //removes Axis Bank project as its required UTM flow to capture PAN, can not generate direct link
            this.setState({ jobList });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }


    showJobLink = (jobId) => {
        let user = store.getState().user;
        let link = `https://link.pickmywork.com/fr/click/${Buffer.from(String(jobId)).toString('base64')}/u/${Buffer.from(String(user.user_id)).toString('base64')}`;
        this.setState({ isShowJobLink: link })
    }

    copyJobLink = (link) => {
        copyCodeToClipboard(link);
        store.dispatch(setError({isSuccess: true, showBar: true, message: "Link Copied!" }))
    }


    render() {
        let { } = this.props;
        let { jobList, searchText, isShowJobLink } = this.state;
        if(searchText) jobList = jobList.filter(o => o.job_title.toLowerCase().includes(searchText.toLowerCase()));
        return (

            <>
            <Row>
                <Col><h4 className='mt-2'>Available Projects</h4></Col>
                <Col xs="12" md="5">
                    <InputFeild placeholder="Search" onChange={(e) => this.setState({ searchText: e.target.value })} />
                </Col>
            </Row>
                
                <div className='p-3'>
                {jobList.map(job => {
                    return <>
                        <Row className="py-3 my-3 shadow-sm border align-items-center">
                            <Col xs={4} md={1}><img  style={{width: "80%", scale: 1, borderRadius: "50%"}} src={job.Employer.logo_key}/></Col>
                            <Col xs={12} md={5}>
                                <span className='d-block font-weight-bold'>{job.job_title}</span>
                                <span className='small'>{job.rewards}</span>
                            </Col>
                            <Col xs={6} md={4}><span className='small'>{job.max_salary}</span></Col>
                            <Col className="text-right"><Button onClick={() => this.showJobLink(job.job_id)} size="sm" color="link" style={{ color: "#4a58d5" }}>Get Link</Button></Col>
                        </Row>
                    </>
                })}
                </div>

                <Modal centered isOpen={!!isShowJobLink}>
                    <ModalBody>
                        <span className='font-weight-bold d-block'>Link to refer this project to customers</span>
                        <pre className='d-block my-3 p-3 bg-light border'>{isShowJobLink}</pre>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" style={{ background: "#4a58d5" }} size="sm" onClick={() => this.copyJobLink(isShowJobLink)}>Copy Link</Button>
                        <Button color="link" style={{ color: "#4a58d5" }} size="sm" onClick={() => this.setState({isShowJobLink: false})}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </>

        );
    }
}

export default PartnerProjects;
