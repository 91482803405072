const SUPPORT_CONSTANTS = {
    TICKET_TYPE_TEXT: {
        10: "How to Start on PickMyWork",
        20: "Project's Training",
        30: "Pending/Expired Tasks",
        40: "Rejected Tasks",
        50: "Payment Query",
        // 60: "Offers (Mobile Recharge)",
        0: "Others"
    },
    TICKET_TYPE_ICONS: {
       
        0: "/Image/support/query.svg",
        10: "/Image/support/training.svg",
        20: "/Image/support/training.svg",
        30: "/Image/support/pending.svg",
        40: "/Image/support/rejected.svg",
        50: "/Image/support/rupee.svg",
        60: "/Image/support/phone.svg",
       
    }, 
    TICKET_SOURCE_ICONS: {
       
        0: "/Image/support/user.svg",
        1: "/Image/support/call.png",
        2: "/Image/support/email.png",
        3: "/Image/support/user.svg",
        4: "/Image/support/feedback.png"
    },
}

export default SUPPORT_CONSTANTS;
