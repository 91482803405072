import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import MainPage from "../components/MainPage.js";
import DetailsPage from "../components/detailsPage.js";
import Slotselect from "../components/slotselect.js";
import Feedback from "../components/feedback";
import Bueno from "../components/bueno";
import Utm from "../components/utm";
import Mt from "../components/my_teams";
import SupportTicket from "../components/support/ticket";
import SupportManage from "../components/support";
import SupportCreate from "../components/support/ticket_create";
import PartnerRoute from "../components/partner";
import withRouter from "react-router-dom/withRouter.js";

import { InitGA } from "../actions/ga4.js"
import Loader from "../components/global/loader.js";
import ErrorBar from "../components/global/error-bar.js";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const referrer = query.get('referrer')
    if(referrer === "BCCI"){
      InitGA("G-7DWK9Q5VWV");
    } else {
      InitGA("G-EWDKL6NSMT");
    }
  }

  render() {
    return (
      <>
        <Switch>
          <Route exact path="/application/:job_id/:job_location_id/:recruiter_id" component={MainPage} />
          <Route exact path="/slotselect" component={Slotselect} />
          <Route exact path="/detailsPage" component={DetailsPage} />
          <Route exact path="/fb/:job_task_id/:ref_value/:user_id/:otp" component={Feedback} />
          <Route exact path="/bueno/output" component={Bueno} />
          <Route exact path="/utm/:utm_code" component={Utm} />
          <Route exact path="/utm/:utm_code/:jid" component={Utm} />
          <Route exact path="/mt" component={Mt} />
          <Route exact path="/mt/:team_code" component={Mt} />
          <Route exact path="/support" component={SupportManage} />
          <Route exact path="/support/ticket" component={SupportTicket} />
          <Route exact path="/support/create" component={SupportCreate} />

          <Route exact path="/reports" render={() =>  this.props.history.push("/partner/") } />
          <Route exact path="/partner/*" component={PartnerRoute} />


          {/* <Route exact path="/mt/:user_code" component={Mt}/> */}
          {/* <Route
            path="/"
            component={() => {
              return <div>404 not found</div>;
            }}
          /> */}
          <Route path="/" render={() => <Redirect to="/partner/" />} />
        </Switch>
        
        <Loader />
        <ErrorBar />
      </>
    );
  }
}

export default withRouter((props) => <Routes {...props} />);
