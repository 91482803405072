
import React, {Component} from 'react';

import { Link, withRouter } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import { setError, setLoader, setUser } from '../../actions/action-creator';
import store from '../../store';
import { auth } from '../../actions/auth';

class PartnerSideNav extends Component {
   constructor(props) {
        super(props);
        this.state = {
        
        };
   }

   
    componentDidMount(){
    }

    logout = async () => {
        if(!window.confirm("Are you sure to logout?")) return;
        this.setState({ isLoading: true});
        store.dispatch(setLoader(true));
        try{
            await auth.Partner.logout();
            store.dispatch(setUser(null));        
            this.props.history.push("/partner/");
        }
        catch(e){
            store.dispatch(setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString() }) )
        }
        this.setState({isLoading: false});
        store.dispatch(setLoader(false));
    }

   

   render() {
       let  { } = this.props;
       var activeMenu=this.props.location.pathname;
       const tabs = [
        {label: "Profile", icon: "👤", id: 1, url: "/partner/profile"},
        {label: "Projects", icon: "💼", id: 2, url: "/partner/projects"},
        {label: "Payouts", icon: "💰", id: 3, url: "/partner/earnings"},
        {label: "Report", icon: "📝 ", id: 4, url: "/partner/report"},
        {label: "Customers", icon: "👥", id: 5, url: "/partner/customers"},
       ]
    return (

        <>

        <div style={{width: "100%", height: "calc(100vh - 50px)", overflowY: "scroll", background : "#ffffff"}} className="shadow pt-3">
            <ul style={{listStyleType : "none", fontSize: 16, paddingBottom: 50}} className="px-3">

                {tabs.map((tab, i) => 
                <li key={i} className="mt-5" style={{fontSize: 18}}>
                    <Link id={`tab_${tab.id}`} className={"text-decoration-none"} to={tab.url} style={{fontWeight: "500", color: activeMenu === tab.url ? "#4a58d5" : "#000000"}} >
                        <Row>
                            <Col xs={2}><span dangerouslySetInnerHTML={{__html: tab.icon}}></span></Col>
                            <Col><span className="d-flex w-100">{tab.label}</span></Col>
                        </Row>
                    </Link>
                </li>
                )}

                <li className="mt-4" style={{fontSize: 16, cursor: "pointer"}} onClick={() => this.logout()}>
                    <span className="d-flex w-100">Logout</span>
                </li>
            </ul>
            
            <div style={{fontSize: 10, position: "absolute", bottom: 0, left: 0}} className="w-100 p-2 text-muted mt-2 bg-light">
                <em className='d-block'>Powered By</em>
                <img alt="logo" style={{width: "50%"}} src="/Image/PMW_New_Black_Logo_Website.png" />
                <em className='d-block'>Copyright 2024 PickMyWork. All rights reserved.</em>
            </div>
        </div>
           
        </>

    );
 }
}

export default withRouter(props => <PartnerSideNav {...props} />);