import React, {Component} from 'react';
import {  Input, Row, Col } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import moment from 'moment';
import SUPPORT_CONSTANTS from './support_constants';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import { auth as Auth} from '../../actions/auth';
import { uploadFileToS3, uploadTicketFileToS3 } from '../../actions';
import { io } from "socket.io-client";
import Typewriter from '../global/typewriter_text';

var socket = null;

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams: {},
            ticketData: {},
            chatData: [],
            addChat : false,
            loader: false,
            error:{showBar:false,message:""},
            playIntroVideo: false,
            isTypingAnimation: false,
            isAskRatingFeedback: false,
            isCallBackRequested: false
        };
    }

    async componentDidMount(){
        let searchParams = new URLSearchParams(this.props.location.search);
        let queryParams = {}
        for (const key of searchParams.keys()) {
            queryParams[key] = searchParams.getAll(key).length > 1 ? searchParams.getAll(key) : searchParams.get(key)
        }
        await this.setState({ queryParams })
        await this.fetchTicket();

        const SERVER = process.env.REACT_APP_SOCKET_URL;
		socket = io (SERVER, { transports: ['websocket']});
		socket.emit("join_ticket", {id: this.state.ticketData.ticket_id} );
        socket.on('error', () => socket.emit("join_ticket", {id: this.state.ticketData.ticket_id} ))
        socket.on('disconnect', () => socket.emit("join_ticket", {id: this.state.ticketData.ticket_id} ))
		socket.on('TICKET_CHAT_ADD', (resp) => {
			if(parseInt(resp.direction) === 2) {
                this.setState({ isTypingAnimation: resp.ticket_chat_id })
                this.addChatData({...resp, created_on: moment().toDate() });
            }
		});
		socket.on('TICKET_RESOLVED', (resp) => {
			this.updateTicketToResolved({...resp, created_on: moment().toDate()});
		});
    }

    componentWillUnmount(){
		socket.disconnect();
	}

    fetchTicket = async () => {
        this.setState({loader: true});
        let { queryParams } = this.state;
        try{
          var body = { ...queryParams};
          let resp = await Auth.Support.fetchCreateTicket(body);
          let ticketData = resp.data.data || null;
          await this.setState({ ticketData });
          if(ticketData) this.fetchTicketChats();
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    }

    fetchTicketChats = async () => {
        this.setState({loader: true});
        let { ticketData } = this.state;
        try{
          var body = { user_id: ticketData.user_id, ticket_id: ticketData.ticket_id };
          let resp = await Auth.Support.fetchChat(body);
          let chatData = resp.data.data;
          await this.setState({ chatData });
        }
        catch(e){
            this.setState({error: {showBar: true, message: "Something went wrong"}});
        }
        this.setState({loader: false})
    }

    endTypeWriterAnimation = () => {
        this.setState({ isTypingAnimation: false })
    }

    onBack = () =>  {
        let prevPage = this.props.history.location.state && this.props.history.location.state.from;
        if(prevPage) this.props.history.goBack();
        else window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({action: "TICKET_CLOSE"}));
    }

    fileChangedHandler = (e) => {
        let { addChat } = this.state;
        if(e){
          let file = e.target.files[0];
          e.target.value = null;
          var fileSize = file.size / 1024 / 1024;
          if( (fileSize >= 15 )) return window.alert("File size is "+fileSize.toFixed(2)+"MB, It should be less than 15MB");
          addChat.file = file;
        } else{
          addChat.file = null;
          this.setState({file: null})
        }
        this.setState({addChat})
    }

    sendChat = async (e) => {
        e && e.preventDefault();
        this.setState({loader: true});
        let { ticketData, addChat } = this.state;
        try{
          var body = { user_id: ticketData.user_id, ticket_id: ticketData.ticket_id, chat_message: addChat.chat_message,  direction: 1 };
          if(addChat.file){
            let resp = await uploadTicketFileToS3(addChat.file, {file_purpose: "TICKET", ticket_id: ticketData.ticket_id, user_id: ticketData.user_id})
            body.file_id = resp.file_id
          }
                        
          if([30, 40].indexOf(parseInt(ticketData.status)) || addChat.isReopen) body.isReopen = ""+ticketData.status;
        
          let resp = await Auth.Support.sendChat(body);
          this.setState({ addChat: false });
          if(body.isReopen) this.fetchTicket();
          else{ 
            this.addChatData({...resp.data.data, created_on: moment().toDate(), User: {} })
            if([30, 40].includes(parseInt(ticketData.status))) this.setState({ ticketData: {...ticketData, status: 20} })
          }
        }
        catch(e){
            this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({loader: false})
    }

    showChatAttachment = async (fileId) => {
        this.setState({loader: true});
        let { ticketData } = this.state;
        try{
          var fileResp = await Auth.Support.getFile({file_id: fileId, ticket_id: ticketData.ticket_id, user_id: ticketData.user_id});
          const link = document.createElement('a');
          link.href = fileResp.data.data[0].url;
          link.setAttribute('target', "_blank");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        catch(e){
          console.log(e)
          this.setState({error: {showBar: true, message:  "Something went wrong" }});
        }
        this.setState({loader: false});
    }

    markClose = async (rating, ratingFeedbackForm) => {
        if(ratingFeedbackForm) ratingFeedbackForm.preventDefault();

        if([1, 2].includes(rating) &&  !ratingFeedbackForm) return this.setState({isAskRatingFeedback: rating});
        this.setState({loader: true});
        let { ticketData } = this.state;
        try{
          var body = { user_id: ticketData.user_id, ticket_id: ticketData.ticket_id, rating };
          if(ratingFeedbackForm) body.rating_comment = ratingFeedbackForm.target.rating_comment.value;
          let resp = await Auth.Support.markClose(body);
          this.setState({isAskRatingFeedback: false, ticketData: {...ticketData, status: 40, ...body} });
          if(body.rating_comment){
            await this.setState({ addChat: {chat_message: body.rating_comment} });
            await this.sendChat();
          }
          this.fetchTicketChats();
          this.setState({ addChat: false });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({loader: false})
    }

    addChatData = (chat) => {
        let { chatData } = this.state;
        chatData.push(chat);
        this.setState({ chatData })
    }

    updateTicketToResolved = (history) => {
        let { ticketData, chatData } = this.state;
        ticketData.status = 30;
        chatData.push(history)
        this.setState({ ticketData, chatData });
    }

    callbackRequest = async () => {
        await this.setState({ addChat: { chat_message: "Callback Requested. Please wait.." } });
        await this.sendChat();


        this.setState({loader: true});
        let { ticketData } = this.state;
        try{
            var body = { from_user_id: ticketData.user_id, ticket_id: ticketData.ticket_id };
            let resp = await Auth.Support.ticketCallConnect(body);
            resp.data.data.message && this.setState({error: {showBar: true, message:  resp.data.data.message}});
            this.setState({isCallBackRequested: true});
            await this.fetchTicket();
        }
        catch(e){
            this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({loader: false})

    }

   render() {
    let { ticketData, chatData, addChat } = this.state;
    let reversedChatData = [...chatData].reverse();
    // console.log(ticketData, reversedChatData)
    return (
    <>
        <div style={{width: "100%", height: "100vh", display: "flex", flexDirection: "column"}}>
                <header style={{flex: 1, position: "sticky", top: 0, zIndex: 99, background: "#fff"}}>
                    <Row noGutters className="align-items-center my-2 text-center">
                        <Col xs={2} style={{cursor: "pointer"}} onClick={this.onBack}><strong style={{fontSize: 28}}>&#x2190;</strong></Col>
                        <Col xs={8}> <strong style={{fontSize: 16}}>Query with team #{ticketData.ticket_id}</strong></Col>
                        <Col xs={2}>
                            {ticketData.status < 40 && ticketData.agent_user_id && <Button disabled={!!this.state.isCallBackRequested || !!this.state.isTypingAnimation} size="sm" color="dark" outline className="" style={{borderRadius: 18, fontSize: 12}} onClick={() => this.callbackRequest() }>&#128222;</Button>}
                        </Col>
                    </Row>

                   
                    <div className="shadow-sm  p-2 m-2" style={{ borderRadius: 10}} >
                        <Row noGutters className="align-items-center ">
                            <Col xs={2} className="">
                                <img style={{width: "50%"}} src={SUPPORT_CONSTANTS.TICKET_TYPE_ICONS[ticketData.ticket_type] || "/Image/dummy.jpg"} />
                            </Col>
                            <Col xs={10} className="text-left">
                                <strong className='d-block' style={{fontSize : 14}}>{ticketData.ticket_title}</strong>
                                <span style={{fontSize : 12}}>{SUPPORT_CONSTANTS.TICKET_TYPE_TEXT[ticketData.ticket_type]}</span>
                            </Col>
                        </Row>
                    </div>
                </header>
                <article id="chat-container" style={{flex: 9, overflowY: "scroll", display: "flex", flexDirection: "column-reverse"}} className="bg-light px-3 ">
                    
                    
                {reversedChatData.map((chat, i) => {
                    if(chat.ticket_chat_id)
                        return <>
                        {ticketData.ticket_type === 10 && parseInt(chat.direction) === 2 && chat.created_by === "PIKU" &&
                        <Row className={"my-2 "+(parseInt(chat.direction) === 1 ? "justify-content-end": "")} >
                            <Col xs={"auto"}>
                                <div className='shadow-sm px-3 py-2' style={{borderRadius: 24, backgroundColor: "#fcf1f1", cursor: "pointer"}} onClick={() => this.callbackRequest()}>
                                    <span className='d-block text-danger small' style={{ wordWrap: "break-word", whiteSpace: "pre-line"}}>Need help now? Click here to call support.📞</span>
                                </div>
                            </Col>
                        </Row>
                        }
                        <Row key={i} className={"my-2 "+(parseInt(chat.direction) === 1 ? "justify-content-end": "")} >
                            <Col xs={9}>
                                <div className='shadow-sm p-3' style={{borderRadius: 12, backgroundColor: parseInt(chat.direction) === 1 ? "#f8f8f8":"#fcf1f1"}}>
                                    <Row className="align-items-center small mb-1">
                                        <Col><span className=''>{this.getChatName(chat)}</span></Col>
                                        <Col xs="auto" className={"text-right"}>
                                            <span className='d-block'>{moment(chat.created_on).format("Do MMM hh:mm A")}</span>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.isTypingAnimation === chat.ticket_chat_id ? 
                                        <Typewriter text={chat.chat_message} delay={20} typingIndicator={2000} onEndAnimation={() => this.endTypeWriterAnimation()}/>
                                        :
                                        <span className='d-block' style={{fontSize: 14, wordWrap: "break-word", whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: chat.chat_message}}></span>
                                    }
                                   
                                    {!!chat.translated_message && chat.translated_message !== chat.chat_message && <span className='d-block border-top mt-1 py-1' style={{fontSize: 14, wordWrap: "break-word", whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: chat.translated_message }}></span>}
                                    {!!chat.file_id && <span className="d-block text-danger my-1" style={{cursor: "pointer"}} onClick={() => this.showChatAttachment(chat.file_id)}>Attachment</span>}
                                    {this.getChatAction(chat)}
                                </div>
                            </Col>
                        </Row>
                        </>;
                    else if(chat.call_log_id)
                        return <Row key={i} className={"my-2 "+(parseInt(chat.direction) === 1 ? "justify-content-end": "")} >
                            <Col xs={9}>
                                <div className='shadow-sm p-3' style={{borderRadius: 12, backgroundColor: parseInt(chat.direction) === 1 ? "#f8f8f8":"#fcf1f1"}}>
                                    <Row className="align-items-center small mb-1">
                                        <Col><span className=''>{this.getChatName(chat)}</span></Col>
                                        <Col xs="auto" className={"text-right"}>
                                            <span className='d-block'>{moment(chat.created_on).format("Do MMM hh:mm A")}</span>
                                        </Col>
                                    </Row>
                                    <span className='d-block' style={{fontSize: 14}}>
                                        {chat.direction === 2 && chat.status !== 'completed' ? "📞 Support team tried to calling you, but got failed due to call status - "+chat.status : ""}
                                        {chat.direction === 2 && chat.status === 'completed' ? "📞 Support team connected with you on phone call" : ""}
                                        {chat.direction === 1 && chat.status !== 'completed' ? "📞 You tried calling the support team but could not get through. Don't worry, team will get back to you very soon" : ""}
                                        {chat.direction === 1 && chat.status === 'completed' ? "📞 You connected with support team on phone call" : ""}
                                    </span>
                                </div>
                            </Col>
                        </Row>;
                    else if (chat.ticket_history_id && chat.field_type === "status" && [30].includes(parseInt(chat.field_value)))
                    return <Row  className="justify-content-center">
                        <Col xs={"auto"}>
                            <span className='d-inline-block text-center mb-2 border px-2 py-1' style={{borderRadius: 12, fontSize: 8, opacity: this.state.isTypingAnimation ? 0 : 1}}>
                                {/* {parseInt(chat.field_value) === 40 && "You closed this conversation"} */}
                                {parseInt(chat.field_value) !== 40 && "Support team answered and marked resolved this query"}
                            </span>
                        </Col>
                    </Row>
                    // else if(chat.ticket_history_id && chat.field_type === "status" && parseInt(chat.field_value) === 30){
                    //     let nextHistory = chatData.filter(o => o.ticket_history_id && o.ticket_history_id > chat.ticket_history_id && o.field_type === "status")[0];
                    //     return <Row key={i} className={"my-2 mb-4 justify-content-start"} >
                    //         <Col xs={9}>
                    //             <div className='shadow-sm p-3' style={{borderRadius: 12, backgroundColor: "#fcf1f1"}}>
                    //                 <Row className="align-items-center small mb-1">
                    //                     <Col><span className=''>Pikku</span></Col>
                    //                     <Col xs="auto" className={"text-right"}>
                    //                         <span className='d-block'>{moment(chat.created_on).format("Do MMM hh:mm A")}</span>
                    //                     </Col>
                    //                 </Row>
                    //                 <span className='d-block' style={{fontSize: 14}}>Did you find this answer helpful?</span>
                    //                 <Row className="mt-2 text-center align-items-center justify-content-center">
                    //                     <Col xs={5}>
                    //                         <Button disabled={!!nextHistory} className="w-100" outline={!(nextHistory && parseInt(nextHistory.field_value) < 30)} color="danger" size="sm" style={{borderRadius: 24}} onClick={() => this.setState({ addChat: { isReopen: true } })}>No</Button>
                    //                     </Col>
                    //                     <Col xs={5}>
                    //                         <Button disabled={!!nextHistory} className="w-100" outline={!(nextHistory && parseInt(nextHistory.field_value) > 30)} color="danger" size="sm" style={{borderRadius: 24}} onClick={() => this.markClose()}>Yes</Button>
                    //                     </Col>
                    //                 </Row>
                    //             </div>
                    //         </Col>
                    //     </Row>;
                    // }
                })}
                </article>
                
                {/* {ticketData.status !== 30 && !(reversedChatData[0] && reversedChatData[0].field_type === "status" && parseInt(reversedChatData[0].field_value) === 30) && */}
                <footer style={{flex: 1, position: "sticky", bottom: 0, background: "#fff"}} className="px-2 py-1">
                    {!this.state.isTypingAnimation && ticketData.status === 30 && 
                        <>
                        <span className='d-block text-center small'>Hope we've answered your query. Please share your experience </span>
                        <Row className="justify-content-center text-center" style={{fontSize: 30}}>
                            <Col xs={"auto"} style={{cursor: "pointer"}} onClick={() => this.markClose(1)}>😤</Col>
                            <Col xs={"auto"} style={{cursor: "pointer"}} onClick={() => this.markClose(2)}>😔</Col>
                            <Col xs={"auto"} style={{cursor: "pointer"}} onClick={() => this.markClose(3)}>😐</Col>
                            <Col xs={"auto"} style={{cursor: "pointer"}} onClick={() => this.markClose(4)}>😊</Col>
                            <Col xs={"auto"} style={{cursor: "pointer"}} onClick={() => this.markClose(5)}>😍</Col>
                        </Row>
                        <span className='d-block text-center my-2'>OR</span>
                        </>
                    }
                    {
                        ticketData.status === 40 && <span className='w-100 d-block p-2 bg-dark text-light text-center mb-2 small'>{[0, 4, 5].includes(ticketData.rating) ? "Ticket closed! Thanks! We're glad your query is resolved." : ticketData.rating === 3 ? "Ticket closed! Thanks for your feedback. We're here if you need anything else." : "Ticket closed! We're sorry for any inconvenience. We're working to improve."}</span>
                    }
                    {ticketData.status < 40 && 
                    <Row>
                        <Col>
                            <Button disabled={!!this.state.isTypingAnimation} size="lg" color="danger" className="my-1 p-2 w-100" style={{borderRadius: 18, fontSize: 12}} onClick={() => this.setState({ addChat: {} })}>{ticketData.status === 30 ? "Still have query?" : "Reply"}</Button>
                        </Col>
                        {/* <Col>
                            <Button disabled={!!this.state.isCallBackRequested} size="lg" color="danger" className="my-1 p-2 w-100" style={{borderRadius: 18, fontSize: 12}} onClick={() => this.callbackRequest() }>Callback Request</Button>
                        </Col> */}
                    </Row>
                    }
                    {ticketData.status === 40 && <Button color="link" className="text-danger p-1 w-100" style={{fontSize: 12}} onClick={() => this.setState({ addChat: {} })}>Reopen Ticket</Button>}
                </footer>
                {/* } */}
                        

            </div>

            <Modal  style={{position: "fixed", bottom: 0}} className="w-100 m-0" isOpen={!!addChat} toggle={() => this.setState({addChat: false})}>
                <ModalBody className="">
                <form onSubmit={this.sendChat}>
                    <Row className="">
                        <Col className="text-center"><strong style={{fontSize: 13}}>Update Query</strong></Col>
                    </Row>
                    <Row className="my-2">
                        <Col><b style={{fontSize: 14}}>Describe your query</b></Col>
                    </Row>
                    <Row>
                        <Col><Input maxLength={750} required value={addChat.chat_message} onChange={(e) => { addChat.chat_message = e.target.value; this.setState({addChat}) }} type="textarea" rows="5" placeholder="Type your query here"/> </Col>
                    </Row>
                    <Row className="my-2">
                        <Col><b style={{fontSize: 14}}>Upload Supportive image or document</b></Col>
                    </Row>

                    {!!addChat.file ?
                    <Row>
                        <Col xs={12}>
                            <a target="_blank" href={URL.createObjectURL(addChat.file)}><span className="px-2 my-2 d-inline-block card py-1 small text-dark bg-light" style={{borderRadius: 25}}>File Attached - {addChat.file.name}</span></a>
                            <i className="fi flaticon-cancel ml-2 small" style={{cursor: "pointer"}} onClick={() => this.fileChangedHandler(null)}></i>
                        </Col>
                    </Row>
                    :
                    <div className='border shadow-sm p-2' style={{borderRadius: 14, cursor: "pointer"}} onClick={() => document.getElementById('icon-button-photo').click()}>
                        <Row noGutters className=" text-center">
                            <Col xs={4}><span style={{fontSize: 12}}>📎 Choose File</span></Col>
                            <Col xs={8}><span className="text-muted text-center" style={{fontSize: 12 }}>No file chosen</span></Col>                                    
                        </Row>
                        <input accept="image/png, image/jpg, image/jpeg, video/mp4, application/pdf" id="icon-button-photo" style={{display: "none"}} onChange={this.fileChangedHandler} type="file"/>
                    </div>
                    }
                    <Row>
                        <Col xs={6}>
                            <Button typ="button" outline color="danger" className="my-3 py-1 w-100" style={{borderRadius: 18, fontSize: 14}} onClick={() => this.setState({ addChat: false })}>Close</Button>
                        </Col>
                        <Col xs={6}>                                    
                            <Button color="danger" className="my-3 w-100 py-1" style={{borderRadius: 18, fontSize: 14}} >Submit</Button>
                        </Col>
                    </Row>
                </form>   
                </ModalBody>
            </Modal>
            
            <Modal  style={{position: "fixed", bottom: 0}} className="w-100 m-0" isOpen={!!this.state.isAskRatingFeedback} toggle={() => this.setState({isAskRatingFeedback: false})}>
                <ModalBody className="">
                <form onSubmit={(e) => this.markClose(this.state.isAskRatingFeedback, e)}>
                    <Row className="">
                        <Col className="text-center"><span style={{fontSize: 12}}>Sorry to hear your bad experience. Please tell us how can we improve this</span></Col>
                    </Row>
                    <Row>
                        <Col><Input maxLength={750} name="rating_comment" type="textarea" rows="5" placeholder="Type your feedback here (optional)"/> </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>                                    
                            <Button color="danger" className="my-3 w-100 py-1" style={{borderRadius: 18, fontSize: 14}} >Submit Feedback</Button>
                        </Col>
                    </Row>
                </form>   
                </ModalBody>
            </Modal>
        

            <Modal isOpen={!!this.state.playIntroVideo} toggle={() => this.setState({playIntroVideo: false})}>
                <ModalHeader className="py-2" toggle={() => this.setState({playIntroVideo: false})}>Introduction Video</ModalHeader>
                <ModalBody className="text-center">
                    <iframe style={{ width: "100%", minHeight: 400 }} src="https://www.youtube.com/embed/uE67InMcgYo" title="PickMyWork Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    {/* <video style={{width: "90%"}} autoPlay controls src="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/new_user_training/InShot_20230425_193120625+(1).mp4" /> */}
                </ModalBody>
            </Modal>



            <Loader show={this.state.loader}/>
            <GlobalErrorbar error={this.state.error} handleError={(v, msg) => this.setState({ error: {showBar: v, message: msg } } )}/>   
       
    </>
    );
    }

    getChatName = (chat) => {
        return parseInt(chat.direction) === 1 ? "You" : "Pikku";
    }

    getChatAction = (chat) => {
       if(chat.chat_message && ((chat.chat_message.toLowerCase().includes("introduction") && chat.chat_message.toLowerCase().includes("video")) || chat.chat_message.toLowerCase().includes("how to earn"))){
        return <div className='text-center'>
            <Button style={{color: "#dc3545"}} color="link" onClick={() => this.setState({playIntroVideo: true})}>Watch Video</Button>
        </div>
       }
       else return <></>
    }

}

export default Support ;