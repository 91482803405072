import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { auth } from '../../actions/auth';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import StarSvg from "../../assets/star.svg"
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import CustomInput from 'reactstrap/lib/CustomInput';
import { downloadApiFile } from '../../actions';
class Feedback extends Component {
   constructor(props) {
     super(props);
     this.state = {
      error:{showBar:false,message:""},
      loader:false,
     };
   }

    componentDidMount(){
      document.title = "Bueno Data";
      
    }

    handleError = (value,msg) =>{
      this.setState({error: {showBar: value, message: msg}});
    }

    downloadBuenoData = async (e) => {
      e.preventDefault();
      e.persist();
      this.setState({loader:true})
      var file = e.target.customFile.files[0];
      try{
        let fileData = await auth.user.getS3UploadURL({file_name: file.name});
        let file_url = fileData.data.data['url']
        if(!file_url) throw new Error("Unable to upload file. Please try again later.")
        await auth.user.uploadFileToS3(file_url, file, {headers: {"Content-Type": file.type}})
        var resp = await auth.Other.buenoDownload({Key: fileData.data.data.key, passcode: e.target.password.value});
        downloadApiFile(resp);
      }
      catch(e){
        var err = e.response ? JSON.parse(String.fromCharCode.apply(null, new Uint8Array(e.response.data))).message : e.toString();
        this.handleError(true, err)
      }
      this.setState({loader:false})
    }
   render() {
    return (

        <>
          <Navbar color="light" light expand="md" className="justify-content-center">
            <NavbarBrand>
              <img className="border-right mr-3" height={50} src={"/Image/PMW_New_Black_Logo-Website.png"} />
              
              <img style={{background: "linear-gradient(45deg, #138e61, #1e7e34)"}} height={40} src={"https://buenofinance.in/assets/img/logo/logo1.png"} />
            </NavbarBrand>
          </Navbar>
          <Row className="justify-content-center mt-3" noGutters> 
            <Col lg={4} xs={12} className="p-2">
              <div className="shadow-lg p-3 text-center">
                <h5 className="border-bottom py-3">Data Download</h5>
                <form onSubmit={this.downloadBuenoData}>
                  <Row className="align-items-center mt-5">
                    <Col xs={12} lg={5}><h6>Select Input File</h6></Col>
                    <Col xs={12} lg={7}>
                      <FormGroup>
                        <CustomInput accept=".xlsx,.csv" className="px-2 text-left" type="file" id="exampleCustomFileBrowser" name="customFile" required />
                      </FormGroup>
                    </Col>
                  </Row>
                  <a className="d-block" download  href={"/bueno_input_sample.xlsx"} >Download Sample Input File</a>
                  <Row className="justify-content-center mt-5">
                    <Col lg={6} xs={12}>
                      <Input required type="password" name="password" placeholder="Passcode" />
                    </Col>
                  </Row>
                  <Button className="my-4" color={"danger"}>Generate Data</Button>
                </form>
              </div>
            </Col>
          </Row>
          
          <Loader show={this.state.loader}/>
          <GlobalErrorbar error={this.state.error} handleError={this.handleError}/>
        </>

    );
 }
}

export default Feedback;