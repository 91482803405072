import ReactGA from "react-ga4";

export const InitGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const PageView = (pathname, title) => {
  ReactGA.send({
    hitType: "pageview",
    page: pathname || window.location.pathname,
    title
  });
};

export const TrackEvent = (eventName, params) => {
//   console.log(category, action, label);
  ReactGA.event(eventName, params);
};
