import React from "react"
import { withRouter} from "react-router-dom";
import Count from "./count.js";
import {Row,Col, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import {auth} from "../actions/auth.js"
import QrReader from 'react-qr-reader';
import {GlobalErrorbar} from './error-bar.js';
import {Loader} from './loader.js'



class DetailsPage extends React.Component {
    constructor() {
        super()
        this.state = {
            OTP: '',
            sentOtp: false,
            result: false,
            filepanurl: null,
            panFile:null,
            aadhaarFile:null,
            fileaadharurl: null,
            fileUpload:null,
            userdetail: { name: "", phone: "", aadhar: "" , aadharfileid:"",pan: "" , panfileid:"",},
            error:{showBar:false,message:""},
            loader:false

        }
    }


 async componentDidMount() {
      if(!this.props.location.state){
          this.props.history.push('/')
      }
  }


    handleScan = data => {
      if (data) {
        this.setState({
          result: data

        })
      }
    }

    handleChange = (e) => {
        let {  userdetail} = this.state;
        userdetail[e.target.name]= e.target.value
        this.setState({userdetail})
    }

    onetime = async (e) => {
        let {  userdetail} = this.state;
          e.preventDefault();
          let loginbody = {
              "mobile": userdetail.phone,
              "otp": this.state.OTP
          }
          try {
            let loginStatus = await auth.user.submitOtp(loginbody);
            if(loginStatus.data.status==="success" && loginStatus.status===200 && loginStatus.data.data.user_id){
                  await this.uploadpanFile();
                  await this.uploadaadharFile();
                  let body = {
                      "user_id": loginStatus.data.data.user_id,
                      "recruiter_id": this.props.location.state.recruiter_id,
                      "job_id":this.props.location.state.job.job_id,
                      "job_location_id":this.props.location.state.job.JobLocations[0].job_location_id,
                      "answers":[]
                  }
                  let aadharbody = {
                        "user_id": loginStatus.data.data.user_id,
                        "type": "AADHAAR",
                        "number": userdetail.aadhar,
                        "file_id": userdetail.aadharfileid,
                        "description": "AADHAAR Detail"
                    }
                  let panbody = {
                        "user_id": loginStatus.data.data.user_id,
                        "type": "PAN",
                        "number": userdetail.pan,
                        "file_id": userdetail.panfileid,
                        "description": "Pan Detail"
                    }
                  let personalbody = {
                        "user_id": loginStatus.data.data.user_id,
                        "first_name": userdetail.name,
                    }
                   try {
                    auth.user.addDocument(aadharbody);
                    auth.user.addDocument(panbody);
                    auth.user.updateUser(personalbody);
                    let applystatus = await auth.job.applyJob(body);
                    if(applystatus.data.status==="success" && applystatus.status===200 && loginStatus.data.data.user_id){
                      this.props.history.push({pathname: '/Slotselect', state: { job: this.props.location.state.job,user:loginStatus.data.data}})
                    }
                   }
                   catch (err) {
                          this.handleError(true,err.response.data.message)
                    }  
            }
          } catch (e) {
              this.handleError(true,e.response.data.message)
          }                                                                                               
    }

    next = async (e) => {
          if(e){e.preventDefault()}
          this.setState({loader:true})
          let loginbody = {
              "mobile": this.state.userdetail.phone
          }
          try {
            let loginStatus = await auth.user.login(loginbody);
            this.setState({sentOtp: true,loader:false })
          } catch (e) {
              this.setState({loader:false})
              this.handleError(true,e.response.data.message)
          }
    }


    uploadaadharFile = async (e) => {
        let{aadhaarFile,fileaadharurl,userdetail} = this.state;
            try {
              this.setState({loader:true})
              let fileData = await auth.user.getS3UploadURL({file_name: aadhaarFile.name});
              let file_url = fileData.data.data['url']
              if(!file_url) throw new Error("Unable to upload file. Please try again later.")
              await auth.user.uploadFileToS3(file_url, aadhaarFile, {headers: {"Content-Type": aadhaarFile.type}})
              let body = {
                        "file_key": fileData.data.data.key,
                        "file_name": aadhaarFile.name,
                        "file_purpose": "USER_AADHAR_FILE"
                      }
              let fileRes = await auth.user.addFile(body)
              userdetail['aadharfileid']= fileRes.data.file_id
              this.setState({loader:false})
            } catch (e) {
              this.handleError(true,"Sorry! not able to upload file")
              this.setState({loader:false})
            }
        this.setState({userdetail });
    }

    handleaadharFile = async (e) => {
        let{aadhaarFile,fileaadharurl} = this.state;
        fileaadharurl= URL.createObjectURL(e.target.files[0]);
        aadhaarFile= e.target.files[0]
        
        this.setState({aadhaarFile,fileaadharurl });
    }


    handlepanFile = async (e) => {
        let{panFile,filepanurl,userdetail} = this.state;
        filepanurl= URL.createObjectURL(e.target.files[0]);
        panFile= e.target.files[0] 
        this.setState({panFile,filepanurl });
    }

    uploadpanFile = async (e) => {
        let{panFile,filepanurl,userdetail} = this.state;
            try {
              this.setState({loader:true})
              let fileData = await auth.user.getS3UploadURL({file_name: panFile.name});
              let file_url = fileData.data.data['url']
              if(!file_url) throw new Error("Unable to upload file. Please try again later.")
              await auth.user.uploadFileToS3(file_url, panFile, {headers: {"Content-Type": panFile.type}})
              let body = {
                        "file_key": fileData.data.data.key,
                        "file_name": panFile.name,
                        "file_purpose": "USER_PAN_FILE"
                      }
              let fileRes = await auth.user.addFile(body)
              userdetail['panfileid']= fileRes.data.file_id
              this.setState({loader:false})
            } catch (e) {
              this.handleError(true,"Sorry! not able to upload file")
              this.setState({loader:false})
            }
        this.setState({panFile,filepanurl,userdetail });
    }

    handleError = (value,msg) =>{
      let{error} = this.state;
      error.showBar =value;
      error.message = msg;
      this.setState({error});
    }

                                                                                                                                                                                                                             
    render() {
        return (
              <Row className="justify-content-center my-3">
                <Col xl={3} lg={3} md={3} sm={12} xs={12}> 
                 {!this.state.sentOtp? <Form onSubmit={this.next}>
                     <FormGroup className="border-bottom">
                        <Label for="exampleEmail">Full Name:</Label><br />
                        <input className="otpheader3" type="text" value={this.state.userdetail.name} name="name" placeholder="Enter Your Full Name" onChange={(e)=> this.handleChange(e)} required/>
                      </FormGroup>
                      <FormGroup  className="border-bottom">
                        <Label for="exampleEmail">Phone Number:</Label><br />
                        <input className="otpheader3" type="text" value={this.state.userdetail.phone} name="phone" placeholder="Enter Your Number" onChange={(e)=> this.handleChange(e)} required />
                      </FormGroup>
                      <FormGroup  className="border-bottom">
                        <Label for="examplePassword">Aadhar Card Number:</Label><br />
                        <input className="otpheader3" type="text" value={this.state.userdetail.aadhar} name="aadhar" placeholder="Enter Your Number" onChange={(e)=> this.handleChange(e)} />
                      </FormGroup>
                      <FormGroup className="text-center">
                         <img style={{width:"100%"}}  src={this.state.fileaadharurl || "./Image/aadhar card.png"} alt=""/>
                      </FormGroup>
                      <FormGroup className="text-center">
                           <label className="otpheader4">  Upload Aadhar Card
                               <input type="file" style={{display:"none"}} onChange={(e) =>{this.handleaadharFile(e)}}/>
                           </label>  
                      </FormGroup>
                      <FormGroup  className="border-bottom">
                        <Label for="examplePassword">Pan Card Number:</Label><br />
                        <input className="otpheader3" type="text" value={this.state.userdetail.pan} name="pan" placeholder="Enter Your Number" onChange={(e)=> this.handleChange(e)} />
                      </FormGroup>
                      <FormGroup className="text-center">
                         <img style={{width:"100%"}}  src={this.state.filepanurl || "./Image/pan.jpeg"} alt=""/>
                      </FormGroup>
                      <FormGroup className="text-center">
                           <label className="otpheader4">  Upload Pan Card
                               <input type="file" style={{display:"none"}} onChange={(e) =>{this.handlepanFile(e)}}/>
                           </label>  
                      </FormGroup>
                      <FormGroup className="text-center">
                          <button className="otpheader5" >Next</button>
                      </FormGroup>
                  </Form>:
                       <div>
                          <h2 className="font-weight-bold text-center" > OTP Verification </h2>
                          <Form className="ml-2 mt-5" onSubmit={(e)=> {this.onetime(e)}}>
                                <Row className="justify-content-between px-3">
                                  <span className=" font-weight-bold" >Enter your code</span>
                                  <Count sentAgain={this.next}/>
                                </Row>
                                <FormGroup  className="border-bottom">
                                   <input className=" mainotp2"  type="text"   value={this.state.OTP}   name="OTP" onChange={(e)=> this.setState({  [e.target.name]: e.target.value })} required/> 
                                </FormGroup>
                            <Col xs={12} className="my-3" >
                              <span className=" font-weight-bold" > Sent to +91 - {this.state.userdetail.phone} </span> 
                              <span className=" otpheader6" onClick={()=>{this.setState({sentOtp:false})}} >Change Number?</span>
                            </Col>
                            <FormGroup className="text-center">
                                <button className="otpheader5" >Submit</button>
                            </FormGroup>
                          </Form>
                       </div>}
                       <GlobalErrorbar error={this.state.error} handleError={this.handleError}/>
                       <Loader show={this.state.loader}/>
                  </Col>
                </Row>
        )
    }
}

export default withRouter(DetailsPage);