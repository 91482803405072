import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoader } from '../../actions/action-creator';

class Loader extends Component {
	
	render() {
		let { loader, show } = this.props;
		return (
			<div className={"r-loader" + (loader || show?"":" invisible")}>
				<div className="position-relative">
					<div className="d-flex justify-content-center">
		              <div className="spinner-border" role="status">
		                <span className="sr-only">Loading...</span>
		              </div>
		            </div>
				</div>
			</div>
		)
	}
}


const mapStateToPropsSuccess = state => ({ ...state });

const mapDispatchToPropsSuccess = dispatch => ({
	setLoader: (show) => dispatch(setLoader(show)),
});

export default withRouter(connect(mapStateToPropsSuccess, mapDispatchToPropsSuccess)(Loader));

