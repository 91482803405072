import React from "react"
import { withRouter } from "react-router-dom";
import {auth} from "../actions/auth.js"
import {  Row, Col,Card, CardImg, CardLink,CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';


class MainPage extends React.Component {
    constructor() {
        super()
        this.state = {
          job:{}
        }
       
    }
    async componentDidMount(){
      let{job}= this.state;
     let y= await auth.job.jobByid(parseInt(window.atob(this.props.match.params['job_id']),10));
     if(y.data.data.length>0){
        job =y.data.data[0] 
        job.JobLocations = y.data.data[0].JobLocations.filter(location=>{return (location.job_location_id===parseInt(window.atob(this.props.match.params['job_location_id']),10))})
        if(job.JobLocations.length>0){
                this.setState({job})
        }
         else{
          this.props.history.push('/')
         }
     }
     else{
      this.props.history.push('/')
     }
    }


    save = () => {
          this.props.history.push({ pathname: '/detailsPage', state: { job: this.state.job ,recruiter_id:window.atob(this.props.match.params['recruiter_id']) || null} })
    }
    
    
    render(){
      return (
              <Row className="justify-content-center py-3 px-3">
                <Col xl={3} lg={3} md={3} sm={12} xs={12}> 
                  <Card style={{borderRadius:'20px'}}>
                    <CardBody className="text-center p-0">
                      {this.state.job.Employer && <img  className="login_img"  src={this.state.job.Employer.logo_key} alt=""/>}
                      <CardTitle className="r-subheading-3">{this.state.job.Employer &&  this.state.job.Employer.employer_name}</CardTitle>
                    </CardBody>
                    <CardImg top height="330px" src={this.state.job.banner_key} alt="Card image cap" />
                    <CardBody className="px-0">
                        <Row  className="px-4 ">
                          <Col  xs={5}>
                         {this.state.job.Employer &&<img   className=" login_img2 "  src={this.state.job.Employer.logo_key} alt=""/>}
                         </Col>
                         <Col xs={7} >
                            <Row><span >{this.state.job.title} - {this.state.job.type}</span></Row>
                            <Row><span >{this.state.job.domain} | {this.state.job.JobLocations && this.state.job.JobLocations[0].city}</span></Row>
                            <Row><span >  Pay: {this.state.job.max_salary}</span></Row>
                         </Col>
                        </Row>
                         <Col xs={12} className="text-center px-3 py-3 my-2"  style={{background:(this.state.job.Employer)&&this.state.job.Employer.theme_color||"rgb(228, 0, 1)"}}>
                            <h3 className="yellowtag2 "> {this.state.job.Employer && this.state.job.tag_line} </h3>
                         </Col>
                         <Row className="mx-4 my-1 align-items-center">
                            <i className="fi flaticon-watch" />
                            <span className="r-subheading-4  ml-1">Duration:</span>
                            <span className="ml-1">{this.state.job.duration} months</span>
                         </Row>
                         <Row className="mx-4 my-1 align-items-center">
                            <i className="fi flaticon-gift" />
                            <span className="r-subheading-4 ml-1">Perks:</span>
                            <span className="ml-1">{this.state.job.rewards}</span>
                         </Row>
                         <Row className="mx-4 my-1 align-items-center">
                            <i className="fi flaticon-pin" />
                            <span className="r-subheading-4 ml-1">Location:</span>
                            <span className="ml-1">{this.state.job.JobLocations && this.state.job.JobLocations[0].city}</span>
                         </Row>
                         <Row className="mx-4 mt-2 ">
                           <CardSubtitle className="r-subheading-4" >Job Description:</CardSubtitle>
                         </Row>
                         <Row className="mx-4 mb-2 ">
                            <CardText dangerouslySetInnerHTML={{ __html: this.state.job.description }}></CardText>
                         </Row>
                         <Row className="mx-0 px-0 justify-content-center">
                          <Button size="lg" onClick={()=>{this.save()}} style={{width:"50%",background:(this.state.job.Employer)&&this.state.job.Employer.theme_color||"rgb(228, 0, 1)"}}>Apply Now</Button>
                        </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )    
    }
}

export default withRouter(MainPage);
