import { combineReducers } from 'redux';
import success from './success';
import error from './error';
import user from './user';
import customer from './customer';
import loader from './loader';

export default combineReducers({
  success,
  error,
  user,
  customer,
  loader
});
