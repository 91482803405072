import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputField from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';
import { copyCodeToClipboard, getCurrentLocation, uploadFileToS3, uploadMyTeamFileToS3 } from '../../actions';
import GoogleMapLocation from '../global/google_map_location';

class JobTask extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        this.state = {
            taskData : null,
            inputData: {},
            isOtpInputShow: false,
            otp: null,
            selectLocationModel: false,
            userLocation: null,
            lockedInputData: {}
        };
    }


    componentDidMount(){
        this.fetchProjectData();

        if(this.props.isAddIncompleteDetails){
            let isAddIncompleteDetails = this.props.isAddIncompleteDetails;
            let lockedInputData = {};
            Object.keys(isAddIncompleteDetails.FormResponse.FormResponseData).map(inputId => {
                lockedInputData[inputId] = isAddIncompleteDetails.FormResponse.FormResponseData[inputId].value;
            });
            this.setState({ lockedInputData })
        }

        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition((data) => {
                this.setState({userLocation: { lat: data.coords.latitude, long: data.coords.longitude } });
            }, (err)=> {
                // window.alert(JSON.stringify(err))
            });
        }else{
            window.alert("Not able to fetch location in your browser")
        }
    }

    fetchProjectData = async () => {
        let { setLoader, setError } = this.props;
        let { job_id } = this.props.selectedJob;
        setLoader(true);
        try{
            let body = { job_id }
            let resp = await auth.MyTeam.fetchProjectData(body);
            this.setState({taskData: resp.data.data[0]})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }


    onChangeInput = async (input, value) => {
        let { inputData } = this.state;
        if(["IMAGE", "VIDEO", "FILE"].indexOf(input.input_type) !== -1){
            inputData[input.input_id] = value;
        }
        else {
            inputData[input.input_id] = value;
        }

        this.setState({ inputData })
    }

  
    submitForm = async (e) => {
        e.preventDefault();
        let { setLoader, setError } = this.props;
        let { job_id } = this.props.selectedJob;
        let { inputData, taskData, otp, userLocation } = this.state;
        setLoader(true);
        try{

            if(taskData.formData.is_otp_required && !otp && !this.props.isAddIncompleteDetails){
                return this.sendOtp()
            }

            let body = { 
                form_id: taskData.form_id,
                input: Object.keys(inputData).map(inputId => {
                    return { input_id: inputId, value: inputData[inputId] }
                }),
                otp
             }

            for(let i = 0; i < body.input.length; i++){
                if(body.input[i].value instanceof File){
                    let fileResp = await uploadMyTeamFileToS3(body.input[i].value, {file_purpose: "TASK_DATA"});
                    body.input[i].value = fileResp.file_id;
                }
            }
            let headers = {};
            if(userLocation) headers.location = JSON.stringify(userLocation);
            if(this.props.isAddIncompleteDetails) {
                body.form_response_id = this.props.isAddIncompleteDetails.FormResponse.form_response_id;
                await auth.MyTeam.completeTaskResponse(body, headers)
            }
            else await auth.MyTeam.submitTaskResponse(body, headers);
            setError({showBar: true, message: "Lead added successfully", isSuccess: true})
            this.props.refreshCount();
            this.props.onClose();
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }


    sendOtp = async (mobile) => {
        let { setLoader, setError } = this.props;
        let { inputData, taskData, otp } = this.state;
        this.setState({otp: null})
        setLoader(true);
        try{
            let mobile = inputData[taskData.formData.is_otp_required.input_id];
            let resp = await auth.MyTeam.sendOtp({mobile, feature: "MT"});
            this.setState({otp: null, isOtpInputShow : true});
            this.timer = setInterval(this.countDown, 1000);
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    countDown = () => {
        let timeCounter = this.state.timeCounter ? this.state.timeCounter - 1 : 29;
        if (timeCounter == 0) clearInterval(this.timer);
        this.setState({ timeCounter });
    }

    fetchUserProjectUTMLink = async () => {
        let { setLoader, setError } = this.props;
        let { taskData } = this.state;
        setLoader(true);
        try{
            let body = {
                entity_type: "JOB",
                entity_id: taskData.job_id
            }
            let resp = await auth.MyTeam.fetchTLUtmLink(body);
            setLoader(false);
            return resp.data.data.utm_link;
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    shareLink = async () => {
        let { setLoader, setError } = this.props;
        let { taskData } = this.state;
        let link = "";
        if(taskData.formData.description && taskData.formData.description.data && taskData.formData.description.data.length){
            link =  taskData.formData.description.data[0].data;
        } else {
            link = await this.fetchUserProjectUTMLink();
        }
        link = (link || "").replace("pickmywork.com", "abbecho.com");
        copyCodeToClipboard(link);
        setError({showBar: true, message: "Link Copied!", isSuccess: true})
        if (navigator.share) {
            try {
              await navigator
                .share({url: link, title: "Share Project Link"})
                .then(() => console.log("Hooray! Your content was shared to tha world"));
            } catch (error) {
              console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
          } else {
            console.log( "Web share is currently not supported on this browser. Please provide a callback" );
          }
    }

    render() {
        let { taskData, isOtpInputShow, timeCounter, lockedInputData } = this.state;
        let { teamData, userData, selectedJob, isAddIncompleteDetails  } = this.props;
        
        return (
  

                <div className='text-left pt-2 px-3 shadow-sm'>
                    <Row className="align-items-center">
                        <Col xs={1} style={{cursor: "pointer"}} onClick={() => this.props.onClose()}>
                            <strong style={{fontSize: 26}}>&#8592;</strong>
                        </Col>
                        <Col xs={2}>
                            <img src={selectedJob.logo_key} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} />
                        </Col>
                        <Col>
                            <strong className='d-block' style={{color: "#080808", fontSize: 16}}>{selectedJob.Employer.employer_name} - {selectedJob.title}</strong>
                        </Col>
                    </Row>

                    <div>
                        <strong className="my-3">Fill the details to {isAddIncompleteDetails ? "complete" : "submit"} the lead</strong>
                        
                        {taskData && (taskData.formData.description || taskData.formData.custom_link) && <Button onClick={this.shareLink} className="border border-danger float-right" size="sm" type="submit" color="link" style={{borderRadius: 20, color: "red", fontSize: 12}} ><span className="share mr-2">&lt;</span>Share Link</Button>}
                    </div>
                    <form onSubmit={this.submitForm}>
                        {taskData && taskData.formData.input.sections.map(section => 
                        <>
                            <strong className='small my-2'>{section.heading}</strong>
                            {section.input_groups.map(inputGroup => 
                            <>
                                <strong className='small my-2'>{inputGroup.heading}</strong>
                                {inputGroup.inputs.map(input => {
                                        return <div className='my-2 shadow-sm p-2'>
                                            <strong className='d-block mb-1' style={{fontSize: 12}}>{input.label}{input.validation && input.validation.required && <span className='text-danger'>*</span>}</strong>
                                            {lockedInputData[input.input_id] ? <span className="small">{lockedInputData[input.input_id]}</span> : this.getInputDOM(input)}
                                        </div>
                                })}
                            </>
                            )}
                        </>
                        )}
                        <Button color="dark" style={{borderRadius: 12}} className="w-100 my-3">{isAddIncompleteDetails? "Complete" : "Submit"} Lead</Button>
                    </form>


                    <Modal centered isOpen={!!this.state.selectLocationModel}>
                        <ModalBody className="">
                            {!!this.state.selectLocationModel && <GoogleMapLocation google={window.google} style={{height : "100%" , width : "100%"}} onSelectLocation={this.state.selectLocationModel} onClose={() => this.setState({ selectLocationModel: false })} />    }            
                        </ModalBody>
                    </Modal>



                    <Modal centered isOpen={!!isOtpInputShow}>
                            <ModalBody className="p-5">
                                <form onSubmit={this.submitForm}>
                                    <strong className='d-block text-muted text-center my-2' style={{fontSize: 15}}>Enter OTP</strong>
                                    <strong className='d-block text-muted text-center my-2' style={{fontSize: 12}}>{taskData && taskData.formData.is_otp_required && taskData.formData.is_otp_required.text}</strong>
                                    <InputField type="number" required id="otp_input_myTeam" className="mt-4" name="otp" placeholder="Enter 4 Digit OTP" value={this.state.otp} onChange={(e) => this.setState({otp: e.target.value})} />
                                    
                                    {timeCounter > 0 ? 
                                        <strong className='d-block mt-3 text-muted text-center' style={{fontSize: 12}}>OTP will be expire in <strong style={{fontSize: 12, color: "#e3b705"}}>{timeCounter}</strong> sec </strong>
                                        :
                                        <strong className='d-block mt-3 text-muted text-center' style={{fontSize: 12}}>Didn't received OTP <strong onClick={() => this.sendOtp()} style={{fontSize: 12, color: "#e3b705", cursor: "pointer"}}>Send Again</strong> </strong>
                                    }
                                    <Button className="mb-3 mt-4 w-100" type="submit" color="dark" style={{borderRadius: 20}} >Verify</Button>
                                </form>
                        </ModalBody>
                    </Modal>
                </div>

                
        );
    }



    getInputDOM = (input, props = {}) => {
        let { inputData } = this.state;
        props.required = input.validation && input.validation.required;

        if(["IMAGE", "VIDEO", "FILE"].indexOf(input.input_type) !== -1){
            if(input.input_type === "IMAGE") {
                props.accept = "image/*";
                if(!input.isAllowFileManager) props.capture = true;
            }
            return <InputField {...props} type="file" className="form-input" onChange={(e) => this.onChangeInput(input, e.target.files[0])} />
        }
        else if (input.input_type === "DROPDOWN"){
            return (
            <InputField type="select" {...this.props} value={inputData[input.input_id]} onChange={(e) => this.onChangeInput(input, e.target.value)}>
                <option value="">Select</option>
                {input.input_enum.map(v => <option value={v}>{v}</option>)}
            </InputField>
            )
        }
        else if (input.input_type === "MAP_LOCATION"){
            return <>
                <span className='d-block my-2'>{inputData[input.input_id] || "-"}</span>
                <Button size="sm" color="light" className="border" type="button"  onClick={(e) => this.setState({ selectLocationModel: (value) => this.onChangeInput(input,value) })}><img src="/Image/pin (1).svg" height={15} width={15} /> Detect Location</Button>
            </>
        }
        else {
            return <InputField {...props} type={input.input_format || "text"} placeholder={input.placeholder || "Enter value"} value={inputData[input.input_id]} onChange={(e) => this.onChangeInput(input, e.target.value)} />   
        }
    }

}
export default JobTask;
