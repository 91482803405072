import React, {Component} from 'react';
import {  Nav, NavItem, NavLink, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import moment from 'moment';
import SUPPORT_CONSTANTS from './support_constants';

class SupportManage extends Component {
   constructor(props) {
        super(props);
        this.state = {
            queryParams: {},
            tickets: [],
            activeTab: 1
        };
   }

    async componentDidMount(){
        let searchParams = new URLSearchParams(this.props.location.search);
        let queryParams = {}
        for (const key of searchParams.keys()) {
            queryParams[key] = searchParams.getAll(key).length > 1 ? searchParams.getAll(key) : searchParams.get(key)
        }
        await this.setState({ queryParams })
        await this.fetchTickets();
    }

    onBack = () =>  {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({action: "TICKET_CLOSE"}));
    }

    fetchTickets = async () => {
        this.setState({loader: true});
        let { queryParams } = this.state;
        try{
          var body = { ...queryParams};
          let resp = await Auth.Support.fetchTicket(body);
          let tickets = resp.data.data || [];
          tickets = tickets.map(t => {
            if([0, 10, 30].includes(t.status)) t.isPending = true;
            return t;
          }).sort((a) => a.isPending ? -1 : 1);
          await this.setState({ tickets });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    }

    selectTab = (tabKey) => {
        this.setState({ activeTab: tabKey });
    }

    selectTicket = (ticket) => {
        let { queryParams } = this.state;
        if(window.ReactNativeWebView){
            let body = {
                action: "TICKET_OPEN",
                params: new URLSearchParams({
                    ticket_id: ticket.ticket_id
                }).toString()
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(body));
        }
        else this.props.history.push(`support/ticket?user_id=${queryParams.user_id}&ticket_id=${ticket.ticket_id}`, { from: "support_page" });
    }

    createTicket = (ticket) => {
        let { queryParams } = this.state;
        this.props.history.push("support/create?user_id="+queryParams.user_id, { from: "support_page" });
    }

   render() {
    let { tickets, activeTab } = this.state;
    let activeTabTickets = [...tickets].filter(o => activeTab === 1 ? o.status !== 40 : o.status === 40);
    return (
    <div style={{width: "100%", height: "100vh"}}>

        <header className='p-2' style={{flex: 1, position: "sticky", top: 0, zIndex: 99, background: "#fff"}}>
            <Row noGutters className="align-items-center my-2 text-center">
                <Col xs={2} style={{cursor: "pointer"}} onClick={this.onBack}><strong style={{fontSize: 28}}>&#x2190;</strong></Col>
                <Col xs={8}> <strong style={{fontSize: 16}}>Help & Queries</strong></Col>
                <Col xs={2}></Col>
            </Row>    
        </header>
        {
        tickets.length ? 
        <div className=''>
            {tickets.length - activeTabTickets.length > 0 &&
            <Row noGutters className="py-2 text-center small">
                <Col className={"py-2 "+ (activeTab === 1 ? "text-danger border-bottom border-danger" : "")} style={{cursor: "pointer"}} onClick={() => this.selectTab(1)}>
                    <span className=''>Open Queries</span>
                </Col>
                <Col className={"py-2 "+ (activeTab === 2 ? "text-danger border-bottom border-danger" : "")} style={{cursor: "pointer"}} onClick={() => this.selectTab(2)}>
                    <span className=''>Closed Queries</span>
                </Col>
            </Row>
            }
            <div className='border-top border-bottom p-3'>
            {
            activeTabTickets.length ? 
                activeTabTickets.map(ticket => 
                    <div className='my-3 shadow-sm border' style={{borderRadius: 8, cursor: "pointer", position: "relative"}} onClick={() => this.selectTicket(ticket)} >
                        <div className='bg-light small p-2'>
                            <span>Query #{ticket.ticket_id}</span>
                            <span className='float-right'>{moment(ticket.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")}</span>
                        </div>
                        <Row className="py-2 align-items-center mx-1">
                            {
                                !!ticket.isPending && <span className='bg-danger d-block' style={{height: 14, width: 14, position: "absolute", right: -5, top: -5, borderRadius: "50%"}}></span>
                            }
                            <Col xs={2}>
                                <img className="" style={{width: "100%", aspectRatio: "1"}} src={(ticket.jobData ? ticket.jobData.logo_key : SUPPORT_CONSTANTS.TICKET_TYPE_ICONS[ticket.ticket_type] || SUPPORT_CONSTANTS.TICKET_SOURCE_ICONS[ticket.ticket_source]) ||  "Image/dummy.jpg"} ></img>
                            </Col>
                            <Col xs={9}>
                                <strong className='d-block' style={{fontSize : 14}}>{ticket.ticket_title}</strong>
                                <span style={{fontSize : 12}}>{SUPPORT_CONSTANTS.TICKET_TYPE_TEXT[ticket.ticket_type]} {ticket.jobData ? "| " + ticket.jobData.job_title : ""}</span>
                            </Col>
                            <Col></Col>
                        </Row>  

                    </div>    
                )
            :
            <span className='d-block m-4 text-center small text-muted'>No History</span>

            }
            </div>
            {
                activeTab === 1 && 
                <div className='text-center my-2 pt-4'>
                    <Button color="danger" style={{borderRadius: 24}} className="px-4" onClick={() => this.createTicket()}>Raise New Query</Button>
                </div>
            }
        </div>
    :
        <div className='text-center p-5 m-4'>
            <img src="/Image/support/caller_icon.png" className='mt-5' style={{width: 150}} />
            <h5 className='mt-4'>Hello! I am here for you.</h5>
            <span className='text-secondary d-block mt-4'>
            If you need help regarding how to earn with PickMyWork, project specific training, your ongoing and completed tasks.
            </span>
            <Button color="danger" style={{borderRadius: 24}} className="px-4 mt-5" onClick={() => this.createTicket()}>Raise Query</Button>
        </div>

    }
    </div>
    )
 }
}

export default SupportManage ;