
import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody , Collapse } from 'reactstrap';
import { auth } from '../../actions/auth';
import CONSTANTS from '../../constant';

class ProjectList extends Component {
   constructor(props) {
     super(props);
     this.scrollAnim = null;
     this.state = {
        jobs: [],
        readMoreCollapse : false,
        isShowSuccess: false,
        isOtpInputShow : false,
        timeCounter: 30,
        isShowExpendedCategory: ""
    };
   }

   
    componentDidMount(){
        this.startCardAnimation();
    }

    componentWillUnmount(){
        this.stopCardAnimation();
    }


    startCardAnimation = () => {
        if(!this.scrollAnim) this.scrollAnim = setInterval(this.autoScrollCards, 3000)
    }

    stopCardAnimation = () => {
        clearInterval(this.scrollAnim);
        this.scrollAnim = null;
    }

    autoScrollCards = () => {
        let rowsElem = document.getElementsByClassName("auto-scroll");
        for(let i = 0; i < rowsElem.length; i++){
            rowsElem[i].scrollLeft = rowsElem[i].scrollLeft >= (rowsElem[i].scrollWidth - rowsElem[i].clientWidth - 10) ? 0 : rowsElem[i].scrollLeft + 20 + (i*5);
        }
    }
   

   render() {
       let  { jobList, jobData, selectJob, eligibleJobIds } = this.props;
        let { isShowExpendedCategory } = this.state;
       let jobListByCategory = jobList.reduce((prev, job) => {
        if(!(jobData && jobData.job_id === job.job_id) && job.JobCategory){
            prev[job.JobCategory.title] = prev[job.JobCategory.title] || [];
            prev[job.JobCategory.title].push(job);
        }
        return prev;
       }, {});
       let jobCategories = Object.keys(jobListByCategory);
       if(jobData){
            let selectedCategoryIndex = jobCategories.indexOf(jobData.JobCategory.title);
            if(selectedCategoryIndex > -1){
                jobCategories.splice(selectedCategoryIndex, 1);
                jobCategories.unshift(jobData.JobCategory.title);
            }
       }
    return (

        <>
           <Row className="text-left py-3" style={{backgroundColor: "#f5f9fc"}}>
               
                <Col xs={12}>
                {
                    jobCategories.map((category, i) => {
                        let jobs = jobListByCategory[category];
                        return <div key={i}>
                            <span style={{fontWeight: 700, fontSize: 14}} className='d-block text-secondary mt-3'>{eligibleJobIds && jobData && jobData.job_category_id === category.job_category_id && "You must try other "}{category}</span>
                            {/* <Row noGutters className="align-items-center auto-scroll hide-scroll pt-1" style={{flexWrap: "nowrap", overflowX: "scroll", scrollBehavior: "smooth"}} onMouseEnter={this.stopCardAnimation} onMouseLeave={this.startCardAnimation} > */}
                            <Row noGutters className="align-items-center pt-1" style={{}} >
                                {jobs.slice(0, isShowExpendedCategory === category ? 999 : 9).map(job => {
                                    let jobColor = job.theme_color || "#000000";
                                    return <Col xs={4} className="py-1 px-1" key={job.job_id}>
                                        {eligibleJobIds && CONSTANTS.CIBIL_FLOW_JOB_ID.includes(job.job_id) &&
                                        <div style={{position: "absolute", top: "-12px", zIndex: 99, width: "90%"}} className='text-center'>
                                            {(eligibleJobIds || []).indexOf(job.job_id) > -1 ? 
                                                <span style={{backgroundColor: "#d6f9d6", borderRadius: 5, color: "#019901", fontSize: 9, fontWeight: 600}} className='px-3  border border-success'>&#10003; Eligible</span>
                                                :
                                                <span style={{backgroundColor: "#fbedee", borderRadius: 5, color: "#dc3545", fontSize: 9, fontWeight: 600}} className='px-3  border border-danger'>Not Eligible</span>
                                            }
                                        </div>
                                        }
                                        <Card className="text-center shadow-sm p-1" style={{backgroundImage: `linear-gradient(${jobColor+"20"}, #ffffff 120%)`,  border: "1px solid "+ jobColor+"20", borderRadius: 10, cursor: "pointer", overflow: "hidden"}}  onClick={() => selectJob(job)}>
                                            <div style={{position: "absolute", background: "#ffffff60", width: "200%", height: "90%", left: "-50%", top: "-65%", borderRadius:"50%"}}></div>
                                            <div style={{zIndex: 9}} className="w-100 d-flex justify-content-center align-items-center">
                                                <img className='w-50 p-1 m-1' style={{aspectRatio: "1", border: "1px solid "+ jobColor+"20", borderRadius: "50%"}} src={job.logo_url || ""} alt="Project Logo" />
                                            </div>
                                            <div style={{height: 45, overflow: "hidden"}} className="">
                                                <span className='' style={{fontSize: 14, fontWeight: 700}}>{job.Employer.employer_name}</span>
                                                <span className="d-block small">{job.title}</span>
                                            </div>
                                            <Button size="sm" color="none" className="text-dark m-2 p-1 shadow-sm" style={{ border: "1px solid "+ jobColor+"30", borderRadius: 6, fontSize: 12, fontWeight: 700, backgroundColor: "#eaeaea"}}><i>Get Now</i></Button>
                                        </Card>
                                    </Col>
                                }
                            )}
                            </Row>
                            {isShowExpendedCategory !== category && jobs.length > 9 && <span className='small text-danger d-block text-center font-weight-bold mt-2' style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.setState({ isShowExpendedCategory: category })}>Show All {category}</span>}
                        </div>
                        
                    })
                }
                           
                        
                </Col>


            </Row>
        </>

    );
 }
}

export default ProjectList;
