import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay, infinite, typingIndicator, onEndAnimation }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startAnimation, setStartAnimation] = useState(!typingIndicator);
  useEffect(() => {
    let timeout;

    if (startAnimation && currentIndex < text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      setCurrentIndex(0);
      setCurrentText("");
    }
    else if(currentIndex === text.length) {
      onEndAnimation && onEndAnimation()
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text, startAnimation]);

  useEffect(() => {
    if (typingIndicator) {
      const timeout = setTimeout(() => {
        setStartAnimation(true);
      }, parseInt(typingIndicator) || 2000);
      return () => clearTimeout(timeout);
    }
  }, []);

  return !startAnimation ? 
  <span>
    <span>Typing </span>
    <div className="tidot"></div>
    <div className="tidot"></div>
    <div className="tidot"></div>
  </span>
  :
  <span style={{fontSize: 14, wordWrap: "break-word", whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{ __html: currentText }}></span>;
};

export default Typewriter;
