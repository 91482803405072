import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import Fade from 'reactstrap/lib/Fade';
import { connect } from 'react-redux';
import { setError } from '../../actions/action-creator';


class ErrorComponent extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.error.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 2500)
		}
	}

	onClose = () => {
		this.props.setError({showBar: false})
	}

	render() {
		let { error } = this.props;
		console.log(error);
		return !error.isSuccess ?
			<Fade in={!!error.showBar} unmountOnExit={true} className="error-bar">
				<p className="text-center py-2 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>{error.message || "Something went wrong"}</p>
				<button onClick={this.onClose} className="btn btn-link close-bn"><i className="fi flaticon-cancel" style={{color: "#fa3709"}}></i></button>
			</Fade>
			:
			<Fade in={!!error.showBar} unmountOnExit={true} className="success-bar">
				<p className="text-center py-2 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-success"></i>{error.message || "Action Completed"}</p>
				<button onClick={this.onClose} className="btn btn-link close-bn"><i className="fi flaticon-cancel" style={{color: "#001e03"}}></i></button>
			</Fade>
		
	}
}


const mapStateToPropsSuccess = state => ({ ...state });

const mapDispatchToPropsSuccess = dispatch => ({
	setError: (show) => dispatch(setError(show)),
});

export default withRouter(connect(mapStateToPropsSuccess, mapDispatchToPropsSuccess)(ErrorComponent));

