import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import Projects from './projects';
import ProjectList from './project_list';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';
import { PageView, TrackEvent } from '../../actions/ga4';
import store from "../../store";
import { setCustomer } from '../../actions/action-creator';

class Utm extends Component {
   constructor(props) {
     super(props);
     this.state = {
        isLoadingProfile: false,
        utmCode: null,
        userProfile: null,
        error:{showBar:false,message:""},
        loader:false,
        jobList: [],
        selectedJob: null,
        isMyTeams: !window.location.hostname.includes("pickmywork"),
        eligibleJobIds: false
    };
   }

   componentDidMount(){
    this.changeFavIcon()
       
        var urlParams = this.props.match.params;
        let utmCode = urlParams.utm_code;
        let jobId = urlParams.jid;
       
       if(utmCode === "pmw-cc") TrackEvent("UTM_Page-Website_CC", { job_id: jobId })
       if(utmCode === "pmw-cc" && jobId === "ga") TrackEvent("UTM_Page-Website_CC_GAD", { job_id: jobId })
        this.checkCustomerSession();
        this.fetchUserProfile(utmCode);
        this.fetchProjects(utmCode, jobId);
        PageView("/utm", "Project UTM Page")

   }

   changeFavIcon = () => {
    document.title= !this.state.isMyTeams ? "PickMyWork Services" : "Products List"
    document.querySelector("meta[name='description']").content="You can get the best offers by our certified professionals";

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    // link.href = 'data:image/x-icon;base64,AA';
    if(this.state.isMyTeams) link.href = '/MyTeamWorks_Logo_300.png';
}

    checkCustomerSession = async (utmCode) => {
        try{
            var resp = await Auth.Utm.getCustomerSession()
            store.dispatch(setCustomer(resp.data.data))
        }
        catch(e){
        }
    }
   
   fetchUserProfile = async (utmCode) => {
    this.setState({loader:true, isLoadingProfile: true});
    try{
        var resp = await Auth.Utm.fetchUtmOwnerProfile({utm_code: utmCode})
        let userProfile = resp.data.data[0] || {};
        userProfile = {...userProfile, ...(userProfile.User || {})};
        this.setState({userProfile, utmCode})
    }
    catch(e){
        this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
    }
    this.setState({loader:false, isLoadingProfile: false})
    
   }

    selectJob = async (job) => {
        TrackEvent("Project Select", { 
            source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
            job_id: job.job_id,
            job_category: job.JobCategory && job.JobCategory.title,
        })
        await this.setState({selectedJob: null}); this.setState({selectedJob: job}); window.scrollTo(0, 0);
    }

    setEligibleJobIds = ids => {
        this.setState({ eligibleJobIds: ids })
    }


    fetchProjects = async (utmCode, jobId) => {
        this.setState({loader: true})
        try{
            var resp = await Auth.Utm.getTasks( {utm_code: utmCode, job_id: jobId});
            let jobList = resp.data.data;
            this.setState({jobList});
            let selectedJob = jobId ? jobList.filter(job => job.job_id === parseInt(jobId))[0] : jobList.filter(job => !!job.utm_selected)[0];
            
            if(selectedJob) {
                this.setState({selectedJob});
            }
        }
        catch(e){
            this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({loader: false})
    }



   render() {


    let {isLoadingProfile,  userProfile, utmCode, accessToken, jobList, selectedJob, eligibleJobIds } = this.state;
    let customerData = store.getState().customer;
    
    let commonProps = {
        userProfile, utmCode, 
        jobList: [...jobList], jobData: selectedJob, eligibleJobIds,
        setEligibleJobIds: jobIds => this.setEligibleJobIds(jobIds),
        setLoader: v => this.setState({ loader: !!v }),
        setError: error => this.setState({ error }),
        selectJob: this.selectJob
    }
    return (
        <>
            {isLoadingProfile ? 
            <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 100px)"}}>Loading...</h3>
            : 
            (
                !userProfile ? 
                <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 50px)"}}> <i className='fi flaticon-alert'> </i> Invalid Link/QR Code or Something went wrong, Try again</h3>
                :
                <>
                <Row noGutters className="justify-content-center">
                    <Col lg={4} xs={12} className=" text-center" style={{borderRadius: "10px"}}>
                           
                            <Row noGutters className="pt-1 pb-4" style={{background: "#000", minHeight: 60}}>
                                <Col className="text-center mt-3">
                                    {!this.state.isMyTeams &&<img alt="logo" style={{width: 100}} src="/Image/PMW_New_White_Logo.png" />}
                                </Col>
                              
                            </Row>
                            
                            <div className="text-left w-100 mb-2" style={{ marginTop: "-15px", borderRadius: 16, background: "#fff" }} >
                                    <img className="rounded-circle p-1 bg-light" style={{width: "60px", marginLeft: 15, marginTop: "-30px", aspectRatio: "1"}} src={userProfile.profile_picture_url ||  "/Image/dummy_profile.jpg"} alt="Profile" />
                                    {/* <span className='d-inline-block px-2 rounded text-light m-2 border border-success' style={{background: "#7fcc7f", fontWeight: 500, fontSize: 10}}>&#10003;  Trusted Partner</span> */}
                                    <Row noGutters className="align-items-start px-4 py-2">
                                        <Col className="text-dark">
                                            <span className="d-block" style={{fontSize: 16, fontWeight: 700, lineHeight: 1}}>{userProfile.first_name}</span>                                
                                            <span><em style={{fontSize: 12, fontWeight: 400}}>{this.state.utmCode === "bestcreditcardsindia" ? "07948517803" : this.state.isMyTeams ? "" : "0" + userProfile.mobile}</em></span>
                                        </Col>
                                        <Col xs={"auto"} className="text-right pr-2">
                                            {(this.state.utmCode === "bestcreditcardsindia" || !this.state.isMyTeams ) ? <a href={`tel:${this.state.utmCode === "bestcreditcardsindia" ? "07948517803" : this.state.isMyTeams ? "" : "0" + userProfile.mobile}`} onClick={() => TrackEvent("UTM Partner Call", {source:  this.state.utmCode === "bestcreditcardsindia" ? "bestcreditcardsindia" : (this.state.isMyTeams ? "My Teams" : "PickMyWork App")})}><Button size="sm" outline={true} color="dark border border-light px-2" style={{borderRadius: 24, boxShadow: "0px 0px 2px 1px #e2e2e2"}}><span style={{fontSize: 12}}>&#128222;&nbsp; Call</span></Button></a> : <></>}
                                        </Col>
                                    </Row>
                            </div>
                               


                    
                        <div className='px-3 pb-4'>
                            {!!selectedJob && <Projects {...commonProps} />}


                            {!!jobList.length && <ProjectList  {...commonProps} />}


                            {!jobList.length && !selectedJob && <h6><em>No Products Found</em></h6>}
                        </div>

                        
                        {/* {!this.state.isMyTeams &&
                        <Row className="py-3 px-2 align-items-center" style={{background: "#eeeeee80"}}>
                            <Col xs={5}>
                                <img src={"/Image/utm_link_referral.png"} style={{width: "75%"}} />
                            </Col>
                            <Col xs={7} className="text-right">
                                <span className='d-block text-secondary' style={{fontWeight: 700, fontSize: 18}}>Join PickMyWork Today</span>
                                <a href={userProfile.referral_link} target="_blank"><Button className="my-2 px-4" size="sm" type="submit" color="danger" >Join Now</Button></a>
                                <strong className='d-block text-secondary' style={{fontSize: 12}}>Become our Trusted Partner to earn like {userProfile.first_name}</strong>
                            </Col>
                        </Row>
                        } */}
                    </Col>
                </Row>
                
                </>
            )}
            <Loader show={this.state.loader}/>
            <GlobalErrorbar error={this.state.error} handleError={(v, msg) => this.setState({ error: {showBar: v, message: msg } } )}/>  
        </>
    );
 }
}

export default Utm;