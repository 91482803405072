import React, {Component} from 'react';
import {  Row } from 'reactstrap';


class Count extends Component {
   constructor(props) {
     super(props);
     this.state = {
       countDown:30,
       resend:false,
     };
   }

 componentDidMount() {
   this.interval = setInterval(this.getDeadline, 1000)
 }

 componentWillUnmount() {
   clearInterval(this.interval)
 }

 getDeadline = () => {
   if(this.state.countDown>0){
       this.setState({countDown:this.state.countDown-1})
   }
   else{
     this.setState({resend:true});
   }
 }
 
 render() {
       return (
        <div  >
        {this.state.resend?<span onClick={()=>{this.setState({resend:false,countDown:30});this.props.sentAgain()} }> resend</span>
        :<div>
         <span>{this.state.countDown}</span>
         <span className="ml-1"> secs</span></div>}
       
        </div>

         );
 }
}

export default Count;