import { auth } from './auth';


export const BASE64 = ({ first_name, last_name }) => {
    var ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var Base64 = function () {};
    var _encode = function (value) {
        if (typeof(value) !== 'number') {
            throw 'Value is not number!';
        }
        var result = '', mod;
        do {
            mod = value % 64;
            result = ALPHA.charAt(mod) + result;
            value = Math.floor(value / 64);
        } while(value > 0);
        return result;
    };
    var _decode = function (value) {
        var result = 0;
        for (var i = 0, len = value.length; i < len; i++) {
            result *= 64;
            result += ALPHA.indexOf(value[i]);
        }
        return result;
    };
    Base64.prototype = {
        constructor: Base64,
        encode: _encode,
        decode: _decode
    };

    return Base64;
}

export const uploadFileToS3 = async (fileData, opts) => {
    let fileUrl = await auth.user.getS3UploadURL({file_name: fileData.name, file_purpose: opts.file_purpose });
    let file_url = fileUrl.data.data['url']
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await auth.user.uploadFileToS3(file_url, fileData, {headers: {"Content-Type": fileData.type}})
    let body = {
        "file_key": fileUrl.data.data.key,
        "file_name": fileData.name,
        "file_purpose": opts.file_purpose
    }
    let fileRes = await auth.user.addFile(body)
    return fileRes.data;
}

export const uploadTicketFileToS3 = async (fileData, opts) => {
    let fileUrl = await auth.Support.getS3UploadURL({file_name: fileData.name, file_purpose: opts.file_purpose, ticket_id: opts.ticket_id, user_id: opts.user_id });
    let file_url = fileUrl.data.data['url']
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await auth.user.uploadFileToS3(file_url, fileData, {headers: {"Content-Type": fileData.type}})
    let body = {
        file_key: fileUrl.data.data.key,
        file_name: fileData.name,
        file_purpose: opts.file_purpose,
        ticket_id: opts.ticket_id,
        user_id: opts.user_id
    }
    let fileRes = await auth.Support.addFile(body)
    return fileRes.data.data;
}

export const uploadUtmFileToS3 = async (fileData, opts) => {
    let fileUrl = await auth.Utm.getS3UploadURL({file_name: fileData.name, file_purpose: opts.file_purpose });
    let file_url = fileUrl.data.data['url']
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await auth.user.uploadFileToS3(file_url, fileData, {headers: {"Content-Type": fileData.type}})
    let body = {
        file_key: fileUrl.data.data.key,
        file_name: fileData.name,
        file_purpose: opts.file_purpose,
        user_id: opts.user_id || "UTM_LINK"
    }
    let fileRes = await auth.Utm.addFile(body)
    return fileRes.data.data;
}

export const uploadMyTeamFileToS3 = async (fileData, opts) => {
    let fileUrl = await auth.MyTeam.getS3UploadURL({file_name: fileData.name, file_purpose: opts.file_purpose });
    let file_url = fileUrl.data.data['url']
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await auth.user.uploadFileToS3(file_url, fileData, {headers: {"Content-Type": fileData.type}})
    let body = {
        "file_key": fileUrl.data.data.key,
        "file_name": fileData.name,
        "file_purpose": opts.file_purpose
    }
    let fileRes = await auth.MyTeam.addFile(body)
    return fileRes.data.data;
}

export const uploadPartnerFileToS3 = async (fileData, opts) => {
    let fileUrl = await auth.Partner.getS3UploadURL({file_name: fileData.name, file_purpose: opts.file_purpose });
    let file_url = fileUrl.data.data['url']
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await auth.user.uploadFileToS3(file_url, fileData, {headers: {"Content-Type": fileData.type}})
    let body = {
        "file_key": fileUrl.data.data.key,
        "file_name": fileData.name,
        "file_purpose": opts.file_purpose
    }
    let fileRes = await auth.Partner.addFile(body)
    return fileRes.data.data;
}


export const downloadApiFile = (resp, fileName) => {
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = true;
    const contentDisposition = resp.headers['content-disposition'];
    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2)
            fileName = fileNameMatch[1];
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }


  export const copyCodeToClipboard = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  export const getCurrentLocation = async () => {
    let addressOutput = "";
    const getCoordinates = () => new Promise(function(resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    if (!navigator.geolocation) return alert("Sorry, Geolocation is not supported by this browser."); 
    try{
        let position = await getCoordinates();
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        let address = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBl-oVe_L607C1NZtZXtsdUXxhWR8NwIYI`)
        .then((res) => res.json())
        return JSON.stringify(address.results[0]);
    }
    catch(e){
        if (navigator.permissions) {
            navigator.permissions.query({ name: "geolocation" }).then((res) => {
                if (res.state === "denied") alert("Enable location permissions for this website in your browser settings.");
            });
        } else alert("Unable to access your location. You can continue by submitting location manually."); 
    }
  }

  