export const setSuccess = (success) => dispatch => {
  dispatch({
    type: "SET_SUCCESS",
    payload: success
  })
}

export const setError = (error) => dispatch => {
  dispatch({
    type: "SET_ERROR",
    payload: error
  })
}
export const setUser = (user) => dispatch => {
  dispatch({
    type: "SET_USER",
    payload: user
  })
}

export const setCustomer = (customer) => dispatch => {
  dispatch({
    type: "SET_CUSTOMER",
    payload: customer
  })
}

export const setLoader = (show) => dispatch => {
  dispatch({
    type: "SET_LOADER",
    payload: show
  })
}
