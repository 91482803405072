import React, {Component} from 'react';
import {  ButtonToggle, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody , Collapse } from 'reactstrap';
import { auth } from '../../actions/auth';
import { TrackEvent } from '../../actions/ga4';
import JobInfoBgSvg from "../../assets/job_info_bg.svg"
import CONSTANTS from "../../constant";
import { uploadUtmFileToS3 } from '../../actions';
import store from '../../store';
import { setCustomer } from '../../actions/action-creator';
// import "./App.css";
 

class Projects extends Component {
   constructor(props) {
     super(props);
     this.timer = null;
     this.state = {
        jobs: [],
        taskForm: null,
        userMobileNumber: null,
        userName: null,
        panNumber: null,
        userEarning: 0,
        userInputResp: [],
        readMoreCollapse : false,
        isShowSuccess: false,
        isOtpInputShow : false,
        timeCounter: 60,
        isMyTeams: !window.location.hostname.includes("pickmywork"),
        cibilConsentId: null,
        notEligibleData: false,
        isShowJobInfo: false,
        countryCode: "+91",
        phone : "",
        isCustomerEdit: true
    };
   }

   
    componentDidMount(){
       
        this.fetchTaskData();
        this.changeFavIcon();
    }


    fetchTaskData = async () => {
        let { utmCode, setLoader, setError, jobData } = this.props;
        setLoader(true);
        try{
            let body = {
                job_id: jobData.job_id,
                utm_code: utmCode
            }
            let resp = await auth.Utm.getTaskForm(body);
            let taskForm = resp.data.data[0];
            if(taskForm) taskForm.formData.input = taskForm.formData.input.sections[0].input_groups[0].inputs;
            this.setState({taskForm});


            TrackEvent("UTM Project Visit", { 
                source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
                job_id: jobData.job_id,
                job_category: jobData.JobCategory && jobData.JobCategory.title,
            })
        }
        catch(e){
            setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    changeFavIcon = () => {
        let { jobData, userProfile } = this.props;
        let jobName = (jobData.Employer ? jobData.Employer.employer_name : "Company") +"-" + jobData.title;
        document.title = userProfile.first_name + " | Certified professional";
        // document.title= !this.state.isMyTeams ? jobName + " | PickMyWork Services" : jobName
        document.querySelector("meta[name='description']").content= jobName + " | You can get the best offers by our certified professionals";
    
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = this.state.isMyTeams ? userProfile.profile_picture_url || jobData.logo_url : jobData.logo_url ;
    }
   

    sendOtp = async (e) => {
        e.preventDefault();
        e.persist()
        let customerData = store.getState().customer;
        let { otp } = e.target;
        let { taskForm } = this.state;
        let { utmCode, setLoader, setError, jobData } = this.props;
        setLoader(true);
        clearInterval(this.timer)
        try{
            let userInputResp = [];
            let userMobile = null;
            let userName = null;
            let panNumber = null;
            let inputs = taskForm.formData.input;
            for(let i = 0; i < inputs.length; i++){
                let input = inputs[i];
                if(e.target["input_"+input.input_id] && e.target["input_"+input.input_id].files && e.target["input_"+input.input_id].files[0]){
                    let fileResp = await uploadUtmFileToS3(e.target["input_"+input.input_id].files[0], {file_purpose: "TASK_DATA"});
                    userInputResp.push({ input_id: input.input_id, value: fileResp.file_id });
                } else {
                    let inputValue = e.target["input_"+input.input_id] && e.target["input_"+input.input_id].value;
                    if(inputValue) {
                        if(input.input_id == 2) userName = inputValue;
                        if(input.input_category === "MOBILE"){ 

                            userMobile = String(inputValue)
                                    .replace(/\D/g,'')
                                    .replace(/\s+/g, "")
                                    .replace("+91", "")
                                    .slice(-10);   
                               
                        }
                        if(input.input_category === "PAN_CARD") panNumber = inputValue;
                        userInputResp.push({ input_id: input.input_id, value: inputValue });
                    }
                }
            }
            await this.setState({userInputResp})

            if(customerData && customerData.mobile == userMobile){ 
                return this.submitResponse(e);
            }
           
            if(!userMobile) throw new Error("Mobile number is required");
            if(userMobile.length !== 10)  throw new Error("Invalid Mobile Number");

            if(e.target.userEarning) this.setState({ userEarning: e.target.userEarning.value});

            if(CONSTANTS.CIBIL_FLOW_JOB_ID.indexOf(jobData.job_id) > -1 && panNumber){
                let resp = await auth.user.sendLeadCibilOTP({mobile: userMobile, name: userName, pan_no: panNumber, feature: this.state.isMyTeams ? "MT" : "UTM", project_name: this.props.jobData.slug_name});
                let consentData = resp.data.data;
                this.setState({ cibilConsentId: consentData.consentId });
            }
            else await auth.user.login({mobile: userMobile, countryCode: e.target.countryCode.value , feature: this.state.isMyTeams ? "MT" : "UTM", project_name: this.props.jobData.slug_name });
            
            window.parent && window.parent.postMessage && window.parent.postMessage({ 'func': 'pushGtagEvent', 'message': 'UTM_OTP_Requested'}, "*"); //for BCCI GTag manager
            TrackEvent("UTM OTP Requested", { 
                source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
                job_id: jobData.job_id,
                job_category: jobData.JobCategory && jobData.JobCategory.title,
            })
            await this.setState({isOtpInputShow : true, userMobileNumber: userMobile, userName, panNumber, timeCounter: 60 , countryCode: e.target.countryCode.value});
            this.timer = setInterval(this.countDown, 1000);
            setError({showBar: true, message: "OTP sent to Mobile", isSuccess: true})
        }
        catch(e){
            setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }


    submitResponse = async (e) => {
        e && e.preventDefault();
        let { otp } = e.target;
        let { taskForm, userInputResp, panNumber, userName, userMobileNumber, userEarning } = this.state;
        let { utmCode, setLoader, setError, jobData, jobList, setEligibleJobIds } = this.props;
        setLoader(true);
        try{
           

            userInputResp  = userInputResp.map(input=> {
                if(/[^\x00-\x7F]/.test(input.value)) input.value = input.value.normalize("NFKC")
                if(input.input_id === 1) {
                    if (isNaN(input.value))
                    input.value = String(input.value).replace(/\s+/g, "").replace("+91", "");
                    if (!isNaN(input.value)) input.value = Number(input.value);
                }
                return input;
            });
        
           let body = {
            form_id: taskForm.form_id,
            feature: this.state.isMyTeams ? "MT" : "UTM",
            input: userInputResp,
            utm_code: utmCode,
            otp: otp && otp.value,
            utm_job_id: jobData.job_id
           }

           if(!store.getState().customer){
            var loginResp = await auth.Utm.loginCustomer({ mobile: userMobileNumber, otp: otp.value, utm_code: utmCode, name: userName })
            await store.dispatch(setCustomer(loginResp.data.data))
           }

           if(this.state.cibilConsentId){
            body.consent_id = this.state.cibilConsentId;
            let eligibilityBody = {
                consent_id: this.state.cibilConsentId,
                otp: otp && otp.value,
                feature: this.state.isMyTeams ? "MT" : "UTM",
                pan_no: panNumber,
                mobile: userMobileNumber,
                name: userName,
                earning: userEarning || 500000,
                job_id: jobList.filter(job => CONSTANTS.CIBIL_FLOW_JOB_ID.indexOf(job.job_id) > -1).map(job => job.job_id)
            }
            let eligibilityResp = await auth.Utm.jobEligibilityCheck(eligibilityBody);
            eligibilityResp = eligibilityResp.data.data;
            let eligibleJobIds = eligibilityResp.eligible_job_ids || [];
            let notEligibleJobData = (eligibilityResp.not_eligible_jobs || []).reduce((prev, curr) => {
                prev[curr.job_id] = curr;
                return prev;
            }, {});
            setEligibleJobIds(eligibleJobIds.map(v => parseInt(v)));
            if(notEligibleJobData[jobData.job_id]){
                this.setState({ notEligibleData: notEligibleJobData[jobData.job_id], isOtpInputShow: false });
                setTimeout(() => { document.getElementById("eligibilityErrorDiv") && document.getElementById("eligibilityErrorDiv").scrollIntoView(true);}, 1000);
                TrackEvent("UTM Project Not Eligible", { 
                    source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
                    job_id: jobData.job_id,
                    job_category: jobData.JobCategory && jobData.JobCategory.title,
                })
                return setLoader(false);
            }

           }
            var resp = await auth.Utm.addTaskResponse(body)
            await this.setState({ isOtpInputShow: false, isShowSuccess: true});
            let link =  typeof resp.data.data === "string" && resp.data.data;

            window.parent && window.parent.postMessage && window.parent.postMessage({ 'func': 'pushGtagEvent', 'message': 'Submit-UTM-OTP-btn'}, "*"); //for BCCI GTag manager
  
            TrackEvent("UTM Project Submit", { 
                source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
                job_id: jobData.job_id,
                job_category: jobData.JobCategory && jobData.JobCategory.title,
            })

            if(link){
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.target = "_top";
                a.href = link;
                a.click();
                document.body.removeChild(a);
            }
        }
        catch(e){
            setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    resendOtp = async () => {
        let { setLoader, setError, jobData } = this.props;
        let { taskForm, userMobileNumber, userName, panNumber,countryCode } = this.state;
        setLoader(true);
        clearInterval(this.timer);
        try{
            if(userMobileNumber){
                if(CONSTANTS.CIBIL_FLOW_JOB_ID.indexOf(jobData.job_id) > -1 && panNumber){
                    let resp = await auth.user.sendLeadCibilOTP({mobile: userMobileNumber, name: userName, pan_no: panNumber, feature: this.state.isMyTeams ? "MT" : "UTM", project_name: this.props.jobData.slug_name});
                    let consentData = resp.data.data;
                    this.setState({ cibilConsentId: consentData.consentId });
                }
                else await auth.user.resendOtpLogin({mobile: userMobileNumber, countryCode: this.state.countryCode, feature: this.state.isMyTeams ? "MT" : "UTM", project_name: this.props.jobData.slug_name});
                await this.setState({isOtpInputShow : true, timeCounter: 60});
                this.timer = setInterval(this.countDown, 1000);
                setError({showBar: true, message: "OTP sent to Mobile", isSuccess: true})
            }
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    countDown = () => {
        let timeCounter = this.state.timeCounter ? this.state.timeCounter - 1 : 59;
        if (timeCounter == 0) clearInterval(this.timer);
        this.setState({ timeCounter });
    }

    showJobInformation = () => {
        let { jobData } = this.props;
        this.setState({ isShowJobInfo: true });
        TrackEvent("UTM_Project_More_Info", { 
            source:  this.state.isMyTeams ? "My Teams" : "PickMyWork App",
            job_id: jobData.job_id,
            job_category: jobData.JobCategory && jobData.JobCategory.title,
        })
    }


   render() {
       let  {jobs, isShowSuccess, isOtpInputShow, timeCounter, taskForm, notEligibleData, countryCode, isCustomerEdit} = this.state;
        let {userProfile, jobData, eligibleJobIds} = this.props;
        let customerData = store.getState().customer;
    return (

        <>
           <Row className=" text-left pb-3 mt-2" style={{background: "#fff", borderRadius: 18, position: "relative", zIndex: 1, marginBottom: "-15px"}}>
               
                <Col xs={12}>

                        <div className='p-2' style={{backgroundImage: `linear-gradient(${(jobData.theme_color || "#000000")+"15"}, #ffffff 12%)`, borderRadius: 18}}>
                            <Row noGutters className="align-items-center mb-2" >
                                <Col xs={1} className="p-0">
                                    <img className="rounded-pill" style={{width: "100%", aspectRatio: "1"}} src={jobData.logo_url || ""} alt="logo" />
                                </Col>
                                <Col className="text-left pl-2">
                                    <span className=" d-block" style={{fontSize: 16, fontWeight: 600}}>{jobData.Employer ? jobData.Employer.employer_name : "Employer"} - {jobData.title}</span>                                
                                    <span style={{fontSize: 14, fontWeight: 400}} className="mt-2" >{jobData.JobCategory && jobData.JobCategory.title}</span>
                                </Col>
                                <Col xs={"auto"} className="text-right" style={{ cursor: "pointer" }} onClick={() => this.showJobInformation()}>
                                    <span className=' p-1 text-danger bg-light border border-danger' style={{fontSize: 12, fontWeight: 500, borderRadius: 12}}>&#x24D8; Details</span>
                                </Col>
                            </Row>
                            {/* {!this.state.isMyTeams &&
                            <Row style={{flexWrap: "nowrap", overflowX: "scroll"}}>
                            {
                                jobData.JobMedia && jobData.JobMedia[0] && jobData.JobMedia[0].JobMediaContents.map(media => 
                                    <Col xs={12} className="text-center">
                                    { 
                                        media.youtube_url ? 
                                        <iframe style={{width: "90%", borderRadius: 18}} src={"https://www.youtube.com/embed/"+media.youtube_url} title="YouTube video player" frameborder="0" auto allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        :
                                        <video poster={media.thumbnail_file_url || ""} controls style={{width: "90%", borderRadius: 18}} src={media.file_url}></video>
                                    }
                                    </Col>
                                )
                            }
                            </Row>
                            } */}


                            {!!customerData && !isCustomerEdit &&
                                <div className='mt-4 px-3 py-2 small' style={{borderRadius: 8}}>
                                    <span className='d-block'><b>Your Mobile: </b>{customerData.mobile} <Button size="sm" color="link" style={{fontSize: 12}} onClick={() => this.setState({ isCustomerEdit: true })}>Not you?</Button></span>
                                    {customerData.name && <span className='d-block mt-2'><b>Your Name:</b> {customerData.name}</span>}
                                </div>
                            }

                            <form onSubmit={(e) => this.sendOtp(e)}>
                                {

                                    taskForm ? <>
                                        {
                                            taskForm.formData.input.map((input, i) => {
                                            let isHiddenInput = false;
                                            let defaultTextValue = "";
                                            if(input.input_id === 1 && customerData) {
                                                // isHiddenInput = true;
                                                defaultTextValue = customerData.mobile;
                                            }
                                            if(input.input_id === 2 && customerData && customerData.name) {
                                                // isHiddenInput = true;
                                                defaultTextValue = customerData.name;
                                            }
                                            return <div style={{display: isHiddenInput ? "none" : "block"}} className='mt-2' key={i} >
                                                <strong className='d-block mb-1 text-muted' style={{fontSize: 14}}>{input.label}</strong>
                                                { (() => {
                                                     if(["IMAGE", "VIDEO", "FILE"].indexOf(input.input_type) !== -1){
                                                        let props = {};
                                                        if(input.input_type === "IMAGE") {
                                                            props.accept = "image/*";
                                                            if(!input.isAllowFileManager) props.capture = true;
                                                        }
                                                        return <InputFeild {...props}  required={input.validation && input.validation.required} type="file" className="form-input" name={"input_"+ input.input_id} />
                                                    } else if(input.input_type === "DROPDOWN") 
                                                        return <InputFeild type="select" required={input.validation && input.validation.required} name={"input_"+ input.input_id} placeholder={input.placeholder} >
                                                        <option value="">Select</option>
                                                        {input.input_enum.map(v => <option value={v}>{v}</option>)}
                                                    </InputFeild>

                                                    else if (input.input_id === 1){
                                                        return <>

                                                          
                                                            
                                                            <Row className="m-0">
                                                                <Col xs={3} className="p-1">
                                                                    <InputFeild type="select" required={true} name={"countryCode"} >
                                                                        <option value="+91">+91</option>
                                                                        <option value="+60">+60</option>
                                                                        <option value="+971">+971</option>
                                                                        <option value="+974">+974</option>
                                                                        <option value="+1">+1</option>
                                                                    </InputFeild>                                                           
                                                                 </Col>
                                                                <Col xs={9} className="p-1">
                                                                    <InputFeild defaultValue={defaultTextValue} required={input.validation && input.validation.required} type={input.input_format || "text"}   name={"input_"+ input.input_id } placeholder={input.placeholder} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    } 
                                                    
                                                    else return <InputFeild defaultValue={defaultTextValue} required={input.validation && input.validation.required} type={input.input_format || "text"}   name={"input_"+ input.input_id } placeholder={input.placeholder} />
                                                })()}                                                
                                            </div>
                                            })
                                        }
                                        {CONSTANTS.CIBIL_FLOW_JOB_ID.includes(jobData.job_id) &&
                                        <div className='mt-2'>
                                            <strong className='d-block mb-1 text-muted' style={{fontSize: 14}}>Monthly Income</strong>
                                            <InputFeild type="number" pattern="[0-9]*" required={true} name={"userEarning"} placeholder={"Your Monthly Income"} />
                                        </div>
                                        }
                                    </>
                                    :
                                    <span className='m-5'>Loading...</span>
                                }
                               
                                {!customerData && <span className='mt-2 d-block small text-center' style={{fontSize: 12}}>*I agree to receive important updates and alerts on the provided contact details.</span>}
                               
                                {eligibleJobIds && CONSTANTS.CIBIL_FLOW_JOB_ID.includes(jobData.job_id) && eligibleJobIds.indexOf(parseInt(jobData.job_id)) === -1 ?
                                <div id="eligibilityErrorDiv" className='p-2 mt-3 text-center' style={{background: "#fbedee", borderRadius: 14}}>
                                    <span className='text-danger font-weight-bold'>Sorry, You are not eligible for this product</span>
                                    {notEligibleData && <span className='d-block text-danger small my-2'>Your {notEligibleData.reason_key} should be {notEligibleData.reason_comparison} {notEligibleData.reason_value}</span>}
                                </div>
                                :
                                <Row noGutters className="mt-2">
                                    <Col >
                                        <Button className="py-2 my-2 utm_project_otp_generate_gtag" block type="submit" color="danger" style={{borderRadius: 10, width: "100%", backgroundColor: "#e94040"}} >{customerData ? "Continue on "+ (jobData.Employer ? jobData.Employer.employer_name : "Product") : "Verify using Mobile OTP"}</Button>
                                    </Col>
                                </Row>
                                }
                                
                                {!!jobData.customerTnc && 
                                <div className='my-2 p-2 ' style={{borderRadius: 14, fontSize: 14, backgroundColor: "#faeeee"}}>
                                    <span className="" style={{fontWeight: 500}}>Important Points to Note</span>
                                    <div className='my-1 list-spacing' style={{fontWeight: 300}}  dangerouslySetInnerHTML={{__html: jobData.customerTnc}}></div>
                                </div>
                                }
                                {this.state.isMyTeams &&
                                    <span className='small d-block my-2'>*Collecting necessary details for tracking. You will be redirected to the official {jobData.Employer ? jobData.Employer.employer_name : "Product"} site upon submission.</span>
                                }
                            </form>
                            </div>
                        
                </Col>


                <Modal centered={true} isOpen={!!isOtpInputShow} toggle={() => this.setState({isOtpInputShow: false})} contentClassName="modal-bottomsheet">
                    <ModalBody className="text-center py-4 px-5" >
                        <form onSubmit={(e) => this.submitResponse(e)}>
                            <h5 className='text-center'>Enter Verification OTP</h5>
                            <InputFeild required id="otp_input" name="otp" type="number" className="mt-4" placeholder="Enter OTP" />
                            <p className='mt-4 small'>OTP sent to {this.state.countryCode} {this.state.userMobileNumber} {!!this.state.cibilConsentId && " to validate your Credit Profile"}<Button size="sm" color="link" className="small" style={{color: "#f16868"}} onClick={() => {clearInterval(this.timer); this.setState({isOtpInputShow: false});  } }>Edit Details</Button></p>
                            <Button type="submit" className="mt-4 d-block w-100 submit-utm-otp-gtag" color="dark" style={{borderRadius: 8}}>Continue on {jobData.Employer ? jobData.Employer.employer_name : "Product"}</Button>
                            {timeCounter ? 
                            <span className="text-danger small mt-4 d-block">{"Resend OTP in 0:"+timeCounter}</span>
                            :
                            <Button type="button"  onClick={() => this.resendOtp()} className="mt-4" color="link" style={{color: "#000"}}>Resend OTP</Button>
                            }
                        </form>
                    </ModalBody>
                </Modal>

                <Modal centered={true} isOpen={!!this.state.isShowJobInfo} toggle={() => this.setState({isShowJobInfo: false})} >
                    <ModalBody className="py-4 px-0" >
                        <img className="rounded-pill" style={{width: "16%", marginLeft: "8%", aspectRatio: "1"}} src={jobData.logo_url || ""} alt="logo" />
                        <span className="d-inline-block" style={{fontSize: 12, fontWeight: 500, margin: 10, verticalAlign: "top"}}>{jobData.Employer ? jobData.Employer.employer_name : "Company"} - {jobData.title} {jobData.JobCategory && jobData.JobCategory.title}</span>
                        <span className='text-light' style={{fontSize: 22, zIndex: 1, top: 62, left: "28%", position: "absolute", fontWeight: 600}}>Features Highlight</span>
                        <img src={JobInfoBgSvg} style={{position: "absolute", width: "95%", top: 55, left: 0}} />
                        <Row className="align-items-center mt-5 mx-4">  
                            {
                                (jobData.customerCriteria || "").split(";;").map(criteriaData => {
                                    let criteria = (criteriaData || "").split("_");
                                    return <Col className={"text-center"}>
                                            <span className='' style={{ fontSize: 12 }}>{criteria[0]}</span>
                                            <div className='' >
                                                <span className='small' style={{ fontWeight: 700 }}>{criteria[1]}</span>
                                            </div>
                                    </Col>
                                })
                            }
                        </Row>
                        <div className='my-3 mx-2 job-info-list-style' style={{fontSize: 14}}>
                            {
                                <div dangerouslySetInnerHTML={{__html: jobData.customerFeatures}}></div>
                            }
                        </div>
                        <div className='mx-3 my-2'>
                            <Button className="w-100 py-1" color="dark" outline onClick={() => this.setState({isShowJobInfo: false})}>Apply Now <span className='text-danger float-right font-weight-bold' style={{fontSize: 18}}>&#8594;</span></Button>
                        </div>
                    </ModalBody>
                </Modal>

          


            <Modal isOpen={isShowSuccess} toggle={() => this.setState({isShowSuccess: false})}>
                <ModalBody className="text-center">
                    <img src="../Image/check-success.gif" />
                    <h4 className='m-3 text-success text-center'>Verification Successful</h4>
                    <h5 className='text-center'>Redirecting to {jobData.Employer.employer_name} - {jobData.title}</h5>
                    <img className="rounded-pill mt-3" style={{width: 60, aspectRatio: "1"}} src={jobData.logo_url || ""} alt="logo" />

                </ModalBody>
            </Modal>



            </Row>
        </>

    );
 }
}

export default Projects;