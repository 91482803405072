import React, {Component} from 'react';
import {  Nav, NavItem, NavLink, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import SUPPORT_CONSTANTS from './support_constants';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import moment from 'moment';

class CreateTicket extends Component {
   constructor(props) {
        super(props);
        this.state = {
            queryParams: {},
            userMetaData: {},
            activeStep: "SELECT_TYPE",
            jobList: [],
            formResponseList: [],
            rechargeList: [],
            selectedParams: {},
            loader: false,
            error:{showBar:false,message:""},
        };
    }

    async componentDidMount(){
        let searchParams = new URLSearchParams(this.props.location.search);
        let queryParams = {}
        for (const key of searchParams.keys()) {
            queryParams[key] = searchParams.getAll(key).length > 1 ? searchParams.getAll(key) : searchParams.get(key)
        }
        await this.setState({ queryParams });
        this.fetchUserMetaData(queryParams.user_id);

    }

    onBack = () =>  {
        let prevPage = this.props.history.location.state && this.props.history.location.state.from;
        if(prevPage) this.props.history.goBack();
        else window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({action: "TICKET_CLOSE"}));
    }
    
    fetchUserMetaData = async (user_id) => {
        this.setState({loader: true});
        try{
            var body = { user_id };
            let resp = await Auth.Support.fetchUserMetaData(body);
            let userMetaData = resp.data.data[0] || {};
            await this.setState({ userMetaData });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    } 
    fetchJobs = async (onlyInTaskJobs) => {
        this.setState({loader: true});
        let { queryParams } = this.state;
        try{
            var body = { ...queryParams};
            let resp = await Auth.Support.fetchJobs(body);
            let jobList = resp.data.data || [];
            if(onlyInTaskJobs) jobList = jobList.filter(job => job.Application && job.Application.status >= 7 && job.Application.attempted_on);
            else jobList = jobList.filter(job => job.archive_status === 0 && !(job.Application && job.Application.attempted_on))
            await this.setState({ jobList });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    }

    fetchFormResponse = async (status) => {
        this.setState({loader: true});
        let { queryParams, selectedParams } = this.state;
        try{
          var body = { ...queryParams, job_id: selectedParams.tag_id, status};
          if(status === 0) body.archive_status = [0, 1];
          let resp = await Auth.Support.fetchJobFormResponse(body);
          let formResponseList = resp.data.data || [];
          formResponseList = formResponseList.filter(o => o.status === status)
          await this.setState({ formResponseList });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    }
    fetchRecharge = async () => {
        this.setState({loader: true});
        let { queryParams, selectedParams } = this.state;
        try{
          var body = { ...queryParams};
          let resp = await Auth.Support.fetchRechargeData(body);
          let rechargeList = resp.data.data || [];
          await this.setState({ rechargeList });
        }
        catch(e){
            this.setState({error: {showBar: true, message:  "Not able to get query details." }});
        }
        this.setState({loader: false})
    }

    selectType = async (type) => {
        let { queryParams } = this.state;
        if(["20", "30", "40"].includes(type)){
            await this.setState({selectedParams: {...this.state.selectedParams, ticket_type: type }})
            this.fetchJobs(type === "20" ? false : true);
            this.setState({ activeStep: "SELECT_JOB" });
        }
        else if(["60"].includes(type)){
            await this.setState({selectedParams: {...this.state.selectedParams, ticket_type: type }})
            this.fetchRecharge();
            this.setState({ activeStep: "SELECT_RECHARGE" });
        }
        else{
            let ticketTitle = SUPPORT_CONSTANTS.TICKET_TYPE_TEXT[type];
            if(window.ReactNativeWebView){
                let body = {action: "TICKET_OPEN", params: new URLSearchParams({ticket_type: type, ticket_title: ticketTitle}).toString()}
                window.ReactNativeWebView.postMessage(JSON.stringify(body));
            }
            else this.props.history.push(`/support/ticket?user_id=${queryParams.user_id}&ticket_type=${type}&ticket_title=${ticketTitle}`, { from: "support_page" });
        }
    }

    selectJob = async (job) => {
        let { queryParams, selectedParams } = this.state;
        if(["30", "40"].includes(selectedParams.ticket_type)){
            await this.setState({selectedParams: {...this.state.selectedParams, tag_type: "JOB", tag_id: job.job_id, logo_url: job.Employer.logo_key }})
            this.fetchFormResponse(selectedParams.ticket_type === "30" ? 0 : 2);
            this.setState({ activeStep: "SELECT_FORM_RESPONSE" });
        }
        else{
            let ticketTitle = job.Employer.employer_name + "-" + job.title;
            if(window.ReactNativeWebView){
                let body = {action: "TICKET_OPEN", params: new URLSearchParams({ticket_type: selectedParams.ticket_type, tag_type: "JOB", tag_id: job.job_id, ticket_title: ticketTitle}).toString()}
                window.ReactNativeWebView.postMessage(JSON.stringify(body));
            }
            else this.props.history.push(`/support/ticket?user_id=${queryParams.user_id}&ticket_type=${selectedParams.ticket_type}&tag_type=JOB&tag_id=${job.job_id}&ticket_title=${ticketTitle}`, { from: "support_page" });
        }
    }
   
    selectFormResponse = async (obj) => {
        let { queryParams, selectedParams } = this.state;
        
        let ticketTitle = obj.name + "-" + obj.number;
        if(window.ReactNativeWebView){
            let body = {action: "TICKET_OPEN", params: new URLSearchParams({ticket_type: selectedParams.ticket_type, tag_type: "JOB", tag_id: selectedParams.tag_id, ref_type: "FORM_RESPONSE", ref_id: obj.form_response_id, ticket_title: ticketTitle}).toString()}
            window.ReactNativeWebView.postMessage(JSON.stringify(body));
        }
        else this.props.history.push(`/support/ticket?user_id=${queryParams.user_id}&ticket_type=${selectedParams.ticket_type}&tag_type=JOB&tag_id=${selectedParams.tag_id}&ref_typ=FORM_RESPONSE&ref_id=${obj.form_response_id}&ticket_title=${ticketTitle}`, { from: "support_page" });
        
    }

    selectRecharge = async (obj) => {
        let { queryParams, selectedParams } = this.state;
        
        let ticketTitle = obj.account_number + "-" + obj.amount/100;
        if(window.ReactNativeWebView){
            let body = {action: "TICKET_OPEN", params: new URLSearchParams({ticket_type: selectedParams.ticket_type, tag_type: "SERVICE_PURCHASE", tag_id: 1, ref_type: "ID", ref_id: obj.service_purchase_id, ticket_title: ticketTitle}).toString()}
            window.ReactNativeWebView.postMessage(JSON.stringify(body));
        }
        else this.props.history.push(`/support/ticket?user_id=${queryParams.user_id}&ticket_type=${selectedParams.ticket_type}&tag_type=SERVICE_PURCHASE&tag_id=1&ref_typ=ID&ref_id=${obj.service_purchase_id}&ticket_title=${ticketTitle}`, { from: "support_page" });
        
    }
   
    

   render() {
    let { activeStep, userMetaData, jobList, formResponseList, rechargeList } = this.state;

    return (
        <div style={{width: "100%", height: "100%"}} className='p-2'>

            <header style={{flex: 1, position: "sticky", top: 0, zIndex: 99, background: "#fff"}}>
                <Row noGutters className="align-items-center my-2 text-center">
                    <Col xs={2} style={{cursor: "pointer"}} onClick={this.onBack}><strong style={{fontSize: 28}}>&#x2190;</strong></Col>
                    <Col xs={8}> <strong style={{fontSize: 16}}>Help & Queries</strong></Col>
                    <Col xs={2}></Col>
                </Row>    
            </header>
            {activeStep === "SELECT_TYPE" && 
                <div className='mx-3 my-5'>
                {Object.keys(SUPPORT_CONSTANTS.TICKET_TYPE_TEXT).filter(key => (userMetaData.is_worked ? key >= 20 : key <= 20) || key === 0).map(key => 
                    key !== "0" && <Row noGutters className="my-3 w-100 shadow-sm border p-3 text-secondary align-items-center" style={{borderRadius: 8, fontSize: 14, cursor: "pointer"}} onClick={() => this.selectType(key)}>
                        <Col xs={2}>
                            <img style={{width: "85%"}} src={SUPPORT_CONSTANTS.TICKET_TYPE_ICONS[key] || "/Image/dummy.jpg"} />
                        </Col>
                        <Col>{ SUPPORT_CONSTANTS.TICKET_TYPE_TEXT[key] }</Col>
                    </Row>
                    
                )} 
                    <Row noGutters className="my-3 w-100 shadow-sm border p-3 text-secondary align-items-center" style={{borderRadius: 8, fontSize: 14, cursor: "pointer"}} onClick={() => this.selectType(0)}>
                        <Col xs={2}>
                            <img style={{width: "85%"}} src={SUPPORT_CONSTANTS.TICKET_TYPE_ICONS[0] || "/Image/dummy.jpg"} />
                        </Col>
                        <Col>{ "Others" }</Col>
                    </Row>
                </div>
            }
            {activeStep === "SELECT_JOB" && 
                <div className='mx-5 my-3'>
                    <strong className='d-block text-center my-3'>Select Project</strong>
                    {jobList.length ?
                        jobList.map((job, i) => 
                            <Row key={i} className="my-3 shadow-sm border p-2 text-secondary align-items-center" style={{borderRadius: 8, fontSize: 14, cursor: "pointer"}} onClick={() => this.selectJob(job)}>
                                <Col xs={2}>
                                    <img style={{width: "100%"}} src={job.Employer.logo_key || "/Image/dummy.jpg"} />
                                </Col>
                                <Col>
                                    <span className='d-block'>{job.Employer.employer_name} - {job.title }</span>
                                    <span className='small'>{job.JobCategory && job.JobCategory.title}</span>

                                </Col>
                            </Row>
                        )
                        :
                        <div className='text-center m-5 pt-5'>
                            <strong className='small d-block mt-5'>You don't have any tasks</strong>
                            <Button className="mt-2" size="sm" color="danger" outline={true} onClick={this.onBack}>Back to Queries</Button>
                        </div>
                    } 
                </div>
            }
            {activeStep === "SELECT_FORM_RESPONSE" && 
                <div className='m-3'>
                    <strong className='d-block text-center small my-3'>Select Leads {this.state.selectedParams.ticket_type === "30" && "with extended verification time"}</strong>
                    {formResponseList.length ? 
                    formResponseList.map((obj, i) => 
                        <Row key={i} noGutters className="my-3 shadow-sm border p-2 text-secondary align-items-center" style={{ position: "relative", borderRadius: 8, fontSize: 14, cursor: "pointer"}} onClick={() => this.selectFormResponse(obj)}>
                            <Col xs={2}>
                                <img style={{width: "80%"}} src={this.state.selectedParams.logo_url || "/Image/dummy.jpg"} />
                            </Col>
                            <Col>
                                <span className='d-block'>{obj.name}</span>
                                <span className='small'>{obj.number}</span>
                            </Col>
                            <Col xs="auto" className="small text-right">
                                <span className=''>{moment(obj.created_on).utcOffset("+05:30").format("Do MMM HH:mm")}</span>
                            </Col>
                            {obj.archive_status === 1 && <span className='text-light bg-danger px-1' style={{borderRadius: 8, position: "absolute", right: 0, top: 0, fontSize: 10}}>Expired</span>}
                        </Row>
                    )
                    :
                    <div className='text-center m-5 pt-5'>
                        <strong className='small d-block mt-5'>You don't have {this.state.selectedParams.ticket_type === "30" ? "Pending" : "Rejected"} leads history for selected Project</strong>
                        <Button className="mt-2" size="sm" color="danger" outline={true} onClick={this.onBack}>Back to Queries</Button>
                    </div>
                    } 
                </div>
            }
            {activeStep === "SELECT_RECHARGE" && 
                <div className='mx-5 my-3'>
                    <strong className='d-block text-center my-3'>Select Recharge</strong>
                    {rechargeList.length ? 
                        rechargeList.map((obj, i) => 
                            <Row key={i} className="my-3 shadow-sm border p-2 text-secondary align-items-center" style={{borderRadius: 8, fontSize: 14, cursor: "pointer"}} onClick={() => this.selectRecharge(obj)}>
                                <Col xs={2}>
                                    <img style={{width: "100%"}} src={"/Image/support/phone.svg"} />
                                </Col>
                                <Col>
                                    <span className='d-block'>{ obj.account_number }</span>
                                    <div>
                                        <span className='small'>&#8377;{obj.amount/100}</span>
                                        <span className='float-right small'>{moment(obj.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")}</span>
                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        <div className='text-center m-5 pt-5'>
                            <strong className='small d-block mt-5'>You don't have any recharge history</strong>
                            <Button className="mt-2" size="sm" color="danger" outline={true} onClick={this.onBack}>Back to Queries</Button>
                        </div>
                    } 
                </div>
            }

            <Loader show={this.state.loader}/>
            <GlobalErrorbar error={this.state.error} handleError={(v, msg) => this.setState({ error: {showBar: v, message: msg } } )}/>   
    
        </div>
    );
 }
}

export default CreateTicket ;