export const API_CONFIG={
	BASE_URL: window.location.hostname.includes("merchant.abbecho") ? process.env.REACT_APP_MY_TEAM_UTM_URL : process.env.REACT_APP_BASE_URL,
	MY_TEAM_URL: process.env.REACT_APP_MY_TEAM_URL,
	BASE_PATH:'/api/v2'
}

export const END_POINTS={
	USER:{
		OTP_REQUEST:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/otp/send`,
		RESEND_OTP_REQUEST:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/otp/retry`,
		SUBMIT_OTP:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/user/login/otp`,
    	GET_FILE_URL:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/s3/url/signed`,
    	UPDATEUSER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/profile/basic/update`,
    	ADD_DOCUMENT:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/profile/document/add`,
	    ADD_FILE:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/add`,
	    FETCH_FILE: id =>`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/file/fetch?file_id=${id}`,
	    SEND_CIBIL_OTP: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/utm/leads/otp`,
	},
	JOB:{
		FETCH_ALL:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/fetch/all`,
		FETCH_JOBAPI: id =>`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/fetch/secondary?job_id=${id}`,
	    APPLY_JOB: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/application/add`,
	    FETCH_SLOT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/slot/interview/available/fetch2`,
	    FETCH_BOOKED_SLOT: (id,jobid) =>`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/slot/interview/booking/fetch?user_id=${id}&job_id=${jobid}`,
	    BOOK_SLOT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/slot/interview/booking/add`,
	},
	FEEDBACK: {
		FETCH:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/feedback/task/fetch`,
		FETCH_RESPONSE:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/feedback/response/fetch`,
		ADD_RESPONSE:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/feedback/response/add`,
	},
	OTHER: {
		BUENO_DOWNLOAD:`${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/bueno/data/download`,
	}
}
