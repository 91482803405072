
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class PartnerEarnings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            earningData: {},
            bankDetails: false,
            earningLogs: false
        };
    }


    componentDidMount() {
        this.fetchBankDetails();
        this.fetchEarnings();
        this.fetchEarningLogs();
    }


    fetchBankDetails = async () => {
        try {
            let resp = await Auth.Partner.fetchUserBank({});
            let bankDetails = resp.data.data.filter(o => !!o.is_default)[0] || false;
            this.setState({ bankDetails });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
    }

    fetchEarnings = async () => {
        let { } = this.props;
        store.dispatch(setLoader(true));
        try {

            let resp = await Auth.Partner.fetchEarnings({});
            let earningData = resp.data.data[0] || {};
            this.setState({ earningData });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    fetchEarningLogs = async () => {
        let { } = this.props;
        store.dispatch(setLoader(true));
        try {

            let resp = await Auth.Partner.fetchEarningLogs({});
            let earningLogs = resp.data.data;
            this.setState({ earningLogs });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }


    render() {
        let { } = this.props;
        let { bankDetails, earningData, earningLogs } = this.state;

        return (

            <>
                <h4 className='mt-2'>Payout Details</h4>
                <Row >
                    <Col className="mt-4" xs={6} md={4}>
                        <div className='border shadow px-3 py-4 bg-light'>
                            <Row className=''>
                                <Col><span>Wallet Balance</span></Col>
                                <Col><span className='font-weight-bold'>₹{parseFloat(earningData.total_amount || 0).toFixed(2)}</span></Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="mt-4" xs={6} md={4}>
                        <div className='border shadow px-3 py-4 bg-light'>
                            <Row className=''>
                                <Col><span>Total Earnings</span></Col>
                                <Col><span className='font-weight-bold'>₹{parseFloat(earningData.credit_amount || 0).toFixed(2)}</span></Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="mt-4" xs={12} md={4}>
                        <div className='border shadow px-3 py-4 bg-light'>
                                
                                <Row>
                                    <Col xs={"auto"}><Link className={"text-dark"} to={"/partner/profile/bank"}><span>Bank Details</span></Link></Col>
                                    <Col className={"text-right"}>
                                        {!bankDetails ?  
                                            <span>No Bank Details Added</span>
                                            :
                                            <span>{bankDetails.bank_name} | XXXXX{bankDetails.account_no.substr(bankDetails.account_no.length-4, 4)}</span>
                                        }
                                    </Col>
                                </Row>
                            
                        </div>
                    </Col>
                </Row>
                <h5 className='mt-5 mb-4'>Wallet Logs</h5>
                {
                    earningLogs.length ? earningLogs.map((transaction, i) => 
                    <Row key={i} className="border-bottom my-2" style={{fontSize: 14}}>
                        <Col md={2} xs={12} className={transaction.type === 1 ? "text-success": "text-danger"}>{transaction.type === 1 ? "+": "-"}{transaction.amount}</Col>
                        <Col md={10} xs={12}>
                            <span>{transaction.reason}</span>
                            <span className="d-block small">{transaction.remark} | {moment(transaction.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                        </Col>
                    </Row>
                    )
                    :
                    <h5 className='text-center my-5 text-secondary'>No Data</h5>
                }

            </>

        );
    }
}

export default PartnerEarnings;
