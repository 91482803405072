import React, {Component} from 'react';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';

import { Map, GoogleApiWrapper, Marker, InfoWindow,  } from 'google-maps-react';
import { Button, Col, Row } from 'reactstrap';



const google = window.google;
const googleKey = "AIzaSyBl-oVe_L607C1NZtZXtsdUXxhWR8NwIYI";

class GoogleMapLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLocation: { lat: 28.4117876, lng: 77.0449321 },
            bounds: null
        };
    }

    async componentDidMount(){
       if(navigator.geolocation){
            // navigator.geolocation.getCurrentPosition((data) => {
            //     console.log(data)
            //     // window.alert(JSON.stringify([data.coords.latitude, data.coords.longitude]))
            // }, (err)=> {
            //     window.alert(JSON.stringify(err))
            // });
        }else{
            window.alert("Not able to fetch location in your browser")
        }
    
    }
    
    posError = () => {
        if (navigator.permissions) {
            navigator.permissions.query({ name: "geolocation" }).then((res) => {
            if (res.state === "denied") {
                alert("Enable location permissions for this website in your browser settings.");
            }
            });
        } else {
            alert("Unable to access your location. You can continue by submitting location manually."); // Obtaining Lat/long from address necessary
        }
    };

    onMapLoad = map => {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            const pos = { lat, lng };
            this.setState({ currentLocation: pos });
          }, 
          this.posError,  
          { enableHighAccuracy:true, timeout: 10000, maximumAge: 60 }
        );
      };
    
    onMarkerDragEnd = ( event, map, coords ) => {  
        const { latLng } = coords;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.setState({currentLocation: { lat, lng }})
    }


    selectLocation = async () => {
        let { currentLocation } = this.state;
        if(!currentLocation) return window.alert("Location not selected")
        let {lat, lng} = currentLocation;
        try{
            let addressResp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleKey}`)
                .then((res) => res.json());
            if(addressResp.results.length){
                let locObj = addressResp.results[0];
                this.props.onSelectLocation(locObj.formatted_address + ` (${lat}, ${lng})`);
                this.props.onClose();
            }
        }
        catch(err){
            window.alert("Not able to fetch location")
        }
    }

    render() {
        let { currentLocation } = this.state;
       
        return (
            <div style={{height: "100vh", width: "100%",  position: "relative"}}>
                <Row className="my-1">
                    <Col>
                        <Button className="w-100 mx-1" onClick={() => this.props.onClose()}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button className="w-100 mx-1" onClick={() => this.selectLocation()}>Select Location</Button>
                    </Col>
                </Row>
                <div style={{height: "calc(100vh - 50px)", width: "100%",  position: "relative"}}>
                <div >
                    <Map
                        google={this.props.google}
                        center={this.state.currentLocation}
                        zoom={18}
                        onReady={map => this.onMapLoad(map)}
                        style={this.props.style || {}}
                        onClick={console.log}
                        >
                            <Marker 
                                draggable={true}
                                position={currentLocation} 
                                onDragend={ this.onMarkerDragEnd }
                                // icon={{url: "Image/pin (1).svg", scaledSize: new window.google.maps.Size(25,25)}} 
                            />
                    </Map>
                </div>
            
            </div>
            
        </div>
        );
    }
}

export default GoogleMapLocation;
