
import React, {Component} from 'react';
import {  FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { auth as Auth } from '../../../actions/auth';
import moment from 'moment';
import { downloadApiFile } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';

class LeadsReport extends Component {
   constructor(props) {
     super(props);
     this.state = {
       jobList: [],
       filters: {},
       leadCounts: { Total: 0, Pending: 0, Approved: 0, Rejected: 0 },
       leadsData: { count: 0, inputs: [], rows: [] },
       rowsPerPage: 20,
       currentUserPage: 0,
    };
   }

   
    componentDidMount(){
        this.fetchJobs();
    }
   
    fetchJobs = async () => {
        let { } = this.props;
        store.dispatch(setLoader(true));
        try{

          let resp = await Auth.Partner.fetchProjects({});
          let jobList = resp.data.data;
          this.setState({ jobList });

          if(jobList.length) {
            await this.setState({ filters: { job_id: jobList[0].job_id } });
            this.fetchLeadsCounts();
            await this.fetchLeadsData();
          }
        }
        catch(e){
            store.dispatch(setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString() }) )
        }
        store.dispatch(setLoader(false));
    }

    changeFilters = async (k, v) => {
        let filters = this.state.filters;
        filters[k] = v;
        await this.setState({ filters });
    }

    applyFilters = async (page = 0) => {
        await this.setState({ currentUserPage: page })
        this.fetchLeadsCounts();
        this.fetchLeadsData();
    }

    fetchLeadsCounts = async () => {
        let { setLoader, setError } = this.props;
        let { filters } = this.state;
        try{
            let filtersData = {...filters};
            filtersData.archive_status = [0, 1];
            if(filtersData.status == 3){
                filtersData.status = 0;
                filtersData.archive_status = 1;
            } else if(filtersData.status === "0") filtersData.archive_status = 0;
            let resp = await Auth.Partner.fetchLeadsCount(filtersData);
            let counts = resp.data.data;
            let leadCounts = { 
                Total: (counts.PENDING || 0) + (counts.PENDING_PARTIAL || 0) + (counts.REJECTED || 0) + (counts.VERIFIED || 0) + (counts.archived_count || 0), 
                Pending: (counts.PENDING || 0) + (counts.PENDING_PARTIAL || 0) + (counts.archived_count || 0),
                Approved: counts.VERIFIED || 0,
                Rejected: counts.REJECTED || 0,
            }
            this.setState({ leadCounts });
        }
        catch(e){
            store.dispatch(setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString() }) )
        }
    }
    fetchLeadsData = async () => {
        let { } = this.props;
        let { filters, rowsPerPage, currentUserPage } = this.state;
        store.dispatch(setLoader(true));
        try{
            let filtersData = {...filters};
            filtersData.archive_status = [0, 1];
            if(filtersData.status == 3){
                filtersData.status = 0;
                filtersData.archive_status = 1;
            } else if(filtersData.status === "0") filtersData.archive_status = 0;
            filtersData.limit = rowsPerPage;
            filtersData.offset = currentUserPage * rowsPerPage;

            let resp = await Auth.Partner.fetchLeadsData(filtersData);
            let leadsData = { count: resp.data.data.count || 0, inputs: resp.data.data.inputs || [], rows: resp.data.data.rows || [] };
            this.setState({ leadsData });
        }
        catch(e){
            store.dispatch(setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString() }) )
        }
        store.dispatch(setLoader(false));
    }

    downloadTask = async () => {
        let {  } = this.props;
        let { filters, rowsPerPage, currentUserPage } = this.state;
        store.dispatch(setLoader(true));
        try{
            let filtersData = {...filters};
            filtersData.archive_status = [0, 1];
            if(filtersData.status == 3){
                filtersData.status = 0;
                filtersData.archive_status = 1;
            } else if(filtersData.status === "0") filtersData.archive_status = 0;
            let resp = await Auth.Partner.downloadLeadsData(filtersData);
            downloadApiFile(resp, `Leads_Report_${moment().format("Do_MMM_YY")}.csv`)
        }
        catch(e){
            // setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        store.dispatch(setLoader(false));
    }

   render() {
       let  { } = this.props;
        let { jobList, filters, leadCounts, leadsData, currentUserPage, rowsPerPage } = this.state;
       let selectedJob = jobList.filter(job => job.job_id === parseInt(filters.job_id))[0] || {};
    return (

        <>
            <Row className="align-items-center">
                <Col md={3} xs={12}><h4>Leads Report</h4></Col>

                <Col md={2} xs={6} className="mb-3">
                    <Input type="select" value={filters.job_id || ""} onChange={(e) => this.changeFilters("job_id", e.target.value)}>
                        <option value="" disabled>Select Projects</option>
                        {jobList.map(job => <option key={job.job_id} value={job.job_id}>{job.slug_name}</option>)}
                    </Input>
                </Col>
                <Col md={2} xs={6} className="mb-3">
                    <Input value={filters.status || ""} type="select"  onChange={(e) => this.changeFilters("status", e.target.value)} >
                        <option value="">All Lead Status</option>
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="2">Rejected</option>
                        <option value="3">Expired</option>
                    </Input>
                </Col>
                <Col md={2} xs={6} className="mb-3">
                    <Input placeholder="Start Date" type="date" value={filters.start_date}  onChange={(e) => this.changeFilters("start_date", e.target.value)}  />
                </Col> 
                <Col md={2} xs={6} className="mb-3">
                    <Input placeholder="End Date" type="date"  value={filters.end_date}  onChange={(e) => this.changeFilters("end_date", e.target.value)} />
                </Col>
                
               
            </Row>

            <Row className="align-items-center">
                <Col md={4} xs={6} className="my-2"><InputFeild placeholder="Search by details" value={filters.search_data_value}  onChange={(e) => this.changeFilters("search_data_value", e.target.value)} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.applyFilters()}  }} /></Col>
                <Col md={4} xs={6} className="my-2"><InputFeild placeholder="Search by Comment" value={filters.search_comment}  onChange={(e) => this.changeFilters("search_comment", e.target.value)} onKeyPress={e => { if(e.key === 'Enter') {e.preventDefault(); this.applyFilters()}  }}  /></Col>
                <Col className="text-center">
                    <Button style={{background: "#4a58d5"}} color="primary" onClick={() => this.applyFilters()}>Apply Filters</Button>
                    <Button outline color="primary" className="ml-3" onClick={() => this.downloadTask()}>Download Report</Button>
                </Col>
            </Row>

            <Row className="mt-4">
                {
                    Object.keys(leadCounts).map(key => 
                    <Col md={3} xs={6}>
                        <div className='px-3 py-2 shadow-sm'>
                            <Row>
                                <Col className='font-weight-bold'>{key}</Col>
                                <Col className='text-right'>{leadCounts[key] || 0}</Col>
                            </Row>
                        </div>
                    </Col>
                    )
                }
            </Row>
            <div className="table-responsive mt-4" style={{minHeight: 370}}>
                <Table size="sm" className="table-bordered text-center" >
                    <thead className="sticky-top" style={{fontSize: 14, fontWeight: "bold"}}>
                        <tr className="mt-4 py-2 font-weight-bold bg-secondary text-light">
                            <td style={{minWidth: 120}}> <span>Lead ID</span></td>
                            <td style={{minWidth: 120}}> <span>Created At</span></td>
                            <td style={{minWidth: 120}}> <span>Updated At</span></td>
                            <td style={{minWidth: 120}}> <span>Status</span></td>
                            <td style={{minWidth: 120}}> <span>Comment</span></td>
                            <td style={{minWidth: 120}}> <span>Customer</span></td>
                            {Object.keys(leadsData.inputs).filter(inputId => inputId > 2).map(inputId => <td style={{minWidth: 120}}><span>{leadsData.inputs[inputId]}</span></td>)}
                        </tr>
                    </thead>
                    <tbody style={{}}>
                        {!!leadsData.rows.length &&
                        leadsData.rows.map(lead => 
                            <tr className="small py-2 shadow-sm">
                                <td> <span>{lead.response.lead_id}</span></td>
                                <td> <span>{moment(lead.response.created_on).format("Do MMM'YY HH:mm")}</span></td>
                                <td> <span>{moment(lead.response.modified_on).format("Do MMM'YY HH:mm")}</span></td>
                                <td> <span>{getLeadStatus(lead.response)}</span></td>
                                <td> <span>{lead.response.status_comment}</span></td>
                                <td> 
                                    <span className='d-block'>{lead.response_data[2] || "-"}</span>
                                    {![4, 6].includes(selectedJob.job_category_id) && <span>{lead.response_data[1] || "-"}</span>}
                                </td>
                                {Object.keys(leadsData.inputs).filter(inputId => inputId > 2).map(inputId => <td><span>{lead.response_data[inputId] || ""}</span></td>)}
                            </tr>
                        )}
                    </tbody>
                </Table>
                {!leadsData.rows.length && <h5 className='text-center my-5 text-secondary'>No Data</h5>}
            </div>
            {!!leadsData.rows.length && 
            <div className='text-right'>
                <span className='mr-3 small'>{currentUserPage*rowsPerPage + leadsData.rows.length} out of {leadsData.count}</span>
                <Pagination aria-label="Page navigation example" className="d-inline-block">
                    <PaginationItem>
                        <PaginationLink disabled={!currentUserPage} onClick={() => this.applyFilters(currentUserPage - 1)} prev >Previous</PaginationLink>
                    </PaginationItem> 
                    <PaginationItem>
                        <PaginationLink disabled={((currentUserPage * rowsPerPage) + leadsData.rows.length) >= leadsData.count} onClick={() => this.applyFilters(currentUserPage + 1)} next >Next</PaginationLink>
                    </PaginationItem>
                </Pagination>
            </div>
            }
        </>

    );
 }
}

export default LeadsReport;

const getLeadStatus = (formResponse) => {
    if(formResponse.archive_status) return <span className='text-danger'>Expired</span>
    else if (formResponse.status === 1) return <span className='text-success'>Approved</span>
    else if (formResponse.status === 2) return <span className='text-danger'>Rejected</span>
    else return <span className='text-secondary'>Pending</span>
}