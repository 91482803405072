
export default (state = null, action) => {
  switch (action.type) {
    case "SET_USER":
      if(action.payload===null) return null;
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}