import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputField from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';
import JobTaskReportDetails from './job_task_report_details';
import moment from 'moment';
const TASK_STATUS = {"pending": "Added", "verified": "Verified", "rejected": "Rejected", "archived": "Expired"};
class JobTaskReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobsList : [],
            taskCounts: {},
            isShowDetailedReport: false,
            dateFilter: ["ALL"]
        };
    }


    componentDidMount(){
        this.fetchProjects();
        this.fetchTaskCounts();
    }

    fetchProjects = async () => {
        let { setLoader, setError } = this.props;
        let { dateFilter } = this.state;
        setLoader(true);
        try{
            let body = {};
            if(dateFilter[1]) body.start_date = dateFilter[1];
            if(dateFilter[2]) body.end_date = dateFilter[2];
            let resp = await auth.MyTeam.fetchProjectReport(body);
            this.setState({jobsList: resp.data.data})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }

    fetchTaskCounts = async () => {
        let { setLoader, setError } = this.props;
        let { dateFilter } = this.state;
        try{
            let body = {};
            if(dateFilter[1]) body.start_date = dateFilter[1];
            if(dateFilter[2]) body.end_date = dateFilter[2];
            let resp = await auth.MyTeam.fetchTaskCount(body);
            let data= resp.data.data[0] && resp.data.data[0].ExternalUsers && resp.data.data[0].ExternalUsers[0] ? resp.data.data[0].ExternalUsers[0].task_count : {}
            this.setState({taskCounts: data})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
    }

    applyDateFilter = async (filterType) => {
        let dateFilter = [filterType];
        switch (filterType){
            case "CURR_WEEK":
                dateFilter[1] = moment().startOf("isoWeek");
                break;
            case "LAST_WEEK":
                dateFilter[1] = moment().subtract(1, "week").startOf("isoWeek");
                dateFilter[2] = moment().subtract(1, "week").endOf("isoWeek");
                break;
            case "CURR_MONTH":
                dateFilter[1] = moment().startOf("month");
                break;
            case "LAST_MONTH":
                dateFilter[1] = moment().subtract(1, "month").startOf("month");
                dateFilter[2] = moment().subtract(1, "month").endOf("month");
                break;
            case "LAST_3MONTH":
                dateFilter[1] = moment().subtract(3, "month").startOf("month");
                break;
            case "LAST_6MONTH":
                dateFilter[1] = moment().subtract(6, "month").startOf("month");
                break;
            default:
                break;
        }
        if(dateFilter[1]) dateFilter[1] = dateFilter[1].toDate();
        if(dateFilter[2]) dateFilter[2] = dateFilter[2].toDate();
        await this.setState({ dateFilter });
        this.fetchProjects();
        this.fetchTaskCounts();
    }

    render() {
        let { jobsList, taskCounts, selectedJob, isShowDetailedReport, dateFilter } = this.state;
        let { teamData, userData } = this.props;
        return (
            isShowDetailedReport ? <JobTaskReportDetails {...this.props} data={isShowDetailedReport} onClose={() => this.setState({ isShowDetailedReport: false })} />
            :
            <>
                <div className='position-relative text-left'>
                    <div className='position-absolute' style={{height: 200, width: "100%", background: "#eaf2f1", zIndex: "-1"}}></div>
                    <div className='p-4' >
                        <Row className="align-items-center">
                            <Col xs={2}><img src={teamData && teamData.profile_picture_url ? teamData.profile_picture_url : UserSVG} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                            <Col className=" text-left">
                                <strong className='d-block' style={{color: "#080808", fontSize: 18}}>{ userData.first_name }</strong>
                                <span className='p-1' style={{background: "#699d9c", color: "#fff", borderRadius: 6, fontSize: 10}}>{teamData ? teamData.User.first_name : "Team Leader"}</span>
                            </Col>
                            {/* <Col xs={3} className="mt-2 text-right">
                                <a href={`tel: +91${teamData && teamData.User.mobile}`}><Button size="sm" color="light" style={{textDecoration: "none", borderRadius: "50%", height: 50, width: 50}} ><span style={{color: "#000", fontSize: 28, lineHeight: 1}}>&#9990;</span></Button></a>
                            </Col> */}
                        </Row>


                        <div className='shadow-sm mt-4  bg-light p-3' style={{borderRadius: 12}}>
                            <Row className="border-bottom align-items-center pb-2">
                                <Col>
                                    <strong className='d-block'>My Lead's Report</strong>
                                </Col>
                                <Col xs={"auto"} className="text-right">
                                    <Button size="sm" className="py-1" outline={true} style={{borderRadius: 16, borderWidth: 0, background: "#fac240", color: "#000", fontWeight: 600, fontSize: 12}} onClick={() => this.props.onClose()}>Add New Lead</Button>
                                </Col>
                            </Row>

                            <Row className="mt-2 text-center">
                                {
                                    Object.keys(TASK_STATUS).map(statusKey => 
                                        <Col xs={3} className="">
                                            <strong className='d-block text-muted' style={{fontSize: 12}}>{TASK_STATUS[statusKey]}</strong>
                                            <span className="d-block mt-2">{(taskCounts[statusKey] || 0)}</span>
                                        </Col>
                                    )
                                }
                            </Row>                         
                        </div>

                        <Row className="align-items-center my-3">
                            <Col>
                                <strong style={{cursor: "pointer"}} onClick={() => this.props.onClose()} className=""> &#8592; Back to add lead</strong>
                            </Col>
                            <Col XS={4}>
                                <InputField type="select" value={dateFilter[0]} onChange={(e) =>  this.applyDateFilter(e.target.value) }  bsSize="sm">
                                    {/* <option value="">Select</option> */}
                                    <option value="CURR_WEEK">This Week</option>
                                    <option value="LAST_WEEK">Last Week</option>
                                    <option value="CURR_MONTH">This Month</option>
                                    <option value="LAST_MONTH">Last Month</option>
                                    <option value="LAST_3MONTH">Last 3 Month</option>
                                    <option value="LAST_6MONTH">Last 6 Month</option>
                                    <option value="ALL">All</option>
                                </InputField>
                            </Col>
                        </Row>
                           

                        {jobsList.length ?
                            jobsList.map(job => 
                            <div className='shadow-sm m-2 bg-light p-2' style={{borderRadius: 12}}>
                                <Row className="align-items-center">
                                    <Col xs={"auto"}><img src={job.Employer.logo_key} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                                    <Col className="">
                                        <strong className='d-block' style={{color: "#080808", fontSize: 16}}>{job.Employer.employer_name} - {job.title}</strong>
                                        <span className='small'>{job.JobCategory.title}</span>
                                    </Col>
                                </Row>
                                <Row noGutters className="mt-2 pt-2 border-top">
                                    {
                                    Object.keys(TASK_STATUS).map((statusKey, i) => 
                                        <Col xs={3} className={"px-1"}>
                                            <div style={{background: "#fff", cursor: "pointer"}} className='text-center d-block w-100 small px-2' onClick={() =>  this.setState({ isShowDetailedReport: { status: i, selectedJob: job } })}>
                                                <span>{(job.task_count && job.task_count[statusKey]) || 0}</span>
                                                <span className='d-block'>{TASK_STATUS[statusKey]}</span>
                                            </div>
                                        </Col>
                                    )
                                    }
                                </Row>
                            </div>
                            ) : <em className="m-4 text-center text-secondary d-block">Ohh! No data found</em>
                        }
                    </div>
                        
                </div>
            </>
                
        );
        }
}

export default JobTaskReport;
