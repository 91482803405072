
import React, {Component} from 'react';
import {  FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputField from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody , Collapse } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import CONSTANTS from '../../constant';
import moment from 'moment';
import { setLoader } from '../../actions/action-creator';
import store from '../../store';

class PartnerLogin extends Component {
   constructor(props) {
     super(props);
     this.state = {
       mobile: "",
       isOtpSent: false,
       otp: ""
    };
   }

   
    componentDidMount(){
    }
   
    login = async (e) => {
        e.preventDefault();
        let { setError } = this.props;
        let { mobile, isOtpSent, otp } = this.state;
        store.dispatch(setLoader(true));
        try{
            if(isOtpSent){
                let resp = await Auth.Partner.loginOtp({ mobile, otp });
                this.props.checkSession();
            }
            else{
                let resp = await Auth.Partner.sendOtp({ mobile });
                this.setState({ isOtpSent: true });
            }
        }
        catch(e){
            setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        store.dispatch(setLoader(false));
    }

    


   render() {
       let  { } = this.props;
        let { mobile, otp, isOtpSent } = this.state;
       
    return (

        <div style={{height: "100vh"}}>
            <Row noGutters className="align-items-center justify-content-center" style={{minHeight: "80vh", height: "100%", overflow: "hidden", background: "#4d60dc"}}>
                <Col md={8} className="h-100" style={{...(window.innerWidth < 720 ? { position: "absolute", opacity: 0.1 } : {}) }}>
                    <img style={{width: "100%", height :"100%", objectFit: "cover"}} src="/Image/partner/partner_login_banner.webp" />
                </Col>
                <Col xs={10} md={4} className="d-flex align-items-center h-100">
                    <div className='w-100 p-4'>
                        <form onSubmit={this.login} style={{color: "#f8f8f8"}}>
                            <img width="100" src="/Image/PMW_New_White_Logo.png" style={{filter: "grayscale(1) contrast(0) brightness(1.8)"}} />
                            <h2 className='mt-4'>Welcome to PickMyWork</h2>
                            <span>Platform to manage your offers</span>
                            <InputField required className="my-5" placeholder="Enter Mobile Number" value={mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                            {isOtpSent && <InputField required className="my-5" placeholder="Enter OTP" value={otp} onChange={(e) => this.setState({ otp: e.target.value })} />}
                            <Button color="light" style={{background: "#f7c01f", width: "50%"}}>{isOtpSent? "Submit OTP" : "Login"}</Button>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>

    );
 }
}

export default PartnerLogin;
