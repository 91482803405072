import React, {Component} from 'react';
import {  Card, CardBody, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { auth } from '../../actions/auth';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import StarSvg from "../../assets/star.svg"

class Feedback extends Component {
   constructor(props) {
     super(props);
     this.state = {
      rating: 4,
      location: null,
      feedbackData: {},
      error:{showBar:false,message:""},
      loader:false,
      comment : [],
      comment_other : "",
      isSubmitted: false,
      ref_type: null,
      ref_value: null,
      otp: "",
     };
   }

    componentDidMount(){
      document.title = "PickMyWork Feedback";
      var urlParams = this.props.match.params;
      let entity_id = urlParams.job_task_id;
      let user_id = new Buffer(urlParams.user_id, 'base64').toString('ascii');
      let ref_value = new Buffer(urlParams.ref_value, 'base64').toString('ascii');
      let otp = new Buffer(urlParams.otp, 'base64').toString('ascii');

      this.setState({ entity_type: "JOB_TASK", entity_id, ref_type: "MERCHANT_MOBILE", ref_value, otp})
      this.fetchFeedbackDetail({ entity_type: "JOB_TASK", entity_id, ref_type: "MERCHANT_MOBILE", ref_value, user_id});
      var parentThis = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        parentThis.setState({location : position.coords.latitude + ", "+ position.coords.longitude});
      });
    }

    // checkAlreadySubmit = async (opts) => {
    //   try {
    //     let resp = await auth.Feedback.fetchResponse(opts);
    //     this.setState({isSubmitted: !!resp.data.data.length})
    //   } catch (e) {
    //       this.setState({error : {showBar: true, message: e.response ? e.response.data.message : e.toString()}});
    //   }
    // }

    fetchFeedbackDetail = async (opts) => {
      this.setState({loader:true})
      try {
        let resp = await auth.Feedback.fetch(opts);
        this.setState({feedbackData: resp.data.data || {}})
      } catch (e) {
          this.setState({error : {showBar: true, message: e.response ? e.response.data.message : e.toString()}});
      }
      this.setState({loader:false})
    }

    handleError = (value,msg) =>{
      let{error} = this.state;
      error.showBar =value;
      error.message = msg;
      this.setState({error});
    }

    submitFeedback = async () => {
      let { rating, comment, comment_other, location, entity_type, entity_id, ref_type, ref_value } = this.state;
      try{
        if(comment_other) comment.push(comment_other);
        comment = comment.join(", ")
        let opts = {
          rating, comment, location,  entity_type, entity_id, ref_type, ref_value
        }
        let resp = await auth.Feedback.addResponse(opts);
        this.setState({isSubmitted: true})
      }
      catch(e){
        this.setState({loader:false})
        this.setState({error : {showBar: true, message: e.response ? e.response.data.message : e.toString()}});
      }
    }

   render() {
   let { rating, feedbackData, isSubmitted, comment } = this.state;
   return (

    <Row noGutters className="mt-4 justify-content-center p-2">
        <Col lg={4} xs={12} className="border shadow text-center px-3" style={{borderRadius: "10px"}}>
            <Row className="align-items-center mt-2">
                <Col className="text-left">
                    <img alt="logo" width={150} src="/Image/PMW_New_Black_Logo-Website.png" />
                </Col>
                <Col className="text-right">
                    <span className='d-block text-danger' style={{fontWeight: 700, fontSize: 22}}>Our Services</span>
                    <span style={{fontSize: 14}}>By Trusted Partner</span>
                </Col>
            </Row>
            {feedbackData.User && 
            <Row>
                <Col xs={12}>
                    <Card className="w-100 my-2" style={{ borderRadius: 8}}>
                        <CardBody className="px-2 py-1" >
                            <Row noGutters className="align-items-center">
                                <Col xs={3} className="p-2 pr-3">
                                    <img className="rounded-circle" style={{width: "80%", aspectRatio: "1"}} src={feedbackData.User.profile_picture_url ||  "/Image/dummy_profile.jpg"} alt="Profile" />
                                </Col>
                                <Col className="text-secondary text-left">
                                    <h5 className="mb-1" style={{fontWeight: 600}}>{feedbackData.User.first_name}</h5>                                
                                    <h6 className="" ><em>{feedbackData.User.pmw_id}</em><br />
                                    <em>{feedbackData.User.mobile}</em></h6>
                                </Col>
                                <Col xs={2} className="text-right">
                                    <Button size="sm" outline={false} color="light shadow-sm"><a style={{color: "#303030", fontSize: 28}} href={`tel: +91 ${feedbackData.User.mobile}`}>&#9990;</a></Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
            


            <h5 className="text-center border-bottom pb-2">{"Feedback"}</h5>
            <h6 className='mb-2'>{"Please share your experience with us. Its important to improve our flow bases on your feedback"}</h6>
            {!!feedbackData.consumer_instruction && <div className='my-3' style={{fontSize: 14}} dangerouslySetInnerHTML={{__html: feedbackData.consumer_instruction}}></div>}
            {/* {!!feedbackData.video_url && <video  controls={true}  autoPlay={true} width={"100%"} src={feedbackData.video_url} />} */}
            
            <div className="shadow-sm my-2 p-2 text-center bg-light">
              <h6>Share OTP</h6>
              <h3 className="m-0">{this.state.otp}</h3>
            </div>


            {!isSubmitted ? <>
              <h6 className="mt-4 text-center">Rate our Partner</h6>
              <Row className="justify-content-center my-3">
                {[1, 2, 3, 4, 5].map(i =>
                <Col xs={2}>
                  <img style={{cursor: "pointer", filter: `grayscale(${rating >= i ? 0:1})`}} height={35} width={35} onClick={() => this.setState({rating: i, comment: []})} src={StarSvg} />
                </Col>
                )}
              </Row>
              <br />
              {
              (rating < 4 ? ["Did not explain product properly", "Was in hurry", "Speaking rudely", "Other"] : ["Good Sales Person", "Great conversation", "Explained the product clearly"]).map((text, index, arr) => 
                  <span 
                    style={{borderRadius: 8, fontSize: 12, cursor: "pointer", background: comment.indexOf(text) === -1 ? "#ddd" : "#555", color: comment.indexOf(text) === -1 ? "#333" : "#eee" }} 
                    className={" border border-secondary d-inline-block p-1 m-1"}
                    onClick={() => { let i = comment.indexOf(text); console.log(i); if(i === -1) comment.push(text); else comment.splice(i, 1); this.setState({comment})}}
                  >
                    {text}
                  </span>
                )
              }
              {!!this.state.comment.includes("Other") &&
                <>
                  <h6 className="mt-4">Comment: </h6>
                  <Input type="textarea" name="text" id="exampleText" value={this.state.comment_other} onChange={(e) => this.setState({comment_other : e.target.value})} />
                </>
              }
              <div className="text-center mt-4">
                <Button color="danger" size="sm" onClick={this.submitFeedback}>Submit Feedback</Button>
              </div>
              </>
              :
              <>
                <h6 className="text-center  m-5">Thank you for your valuable feedback</h6>
                <span className="text-center d-block">For any query, you can contact us to <a href="tel:918825154594">+918825154594</a></span>
                <h4 className="text-danger m-5 text-center">Thank You &#x1f44d;</h4>
              </>
            }



            <Row className="py-3 align-items-center mt-3" style={{background: "#eeeeee80"}}>
                <Col xs={5}>
                    <img src={"/Image/utm_link_referral.png"} style={{width: "75%"}} />
                </Col>
                <Col xs={7} className="text-right">
                    <span className='d-block text-secondary' style={{fontWeight: 700, fontSize: 18}}>Join PickMyWork Today</span>
                    <a href={"https://pickmywork.page.link/mch-ref"} target="_blank"><Button className="my-2 px-4" size="sm" type="submit" color="danger" >Join Now</Button></a>
                    <strong className='d-block text-secondary' style={{fontSize: 12}}>Become our Trusted Partner to earn</strong>
                </Col>
            </Row>
        </Col>
    </Row>

   )
    return (
        <>
          <Navbar color="light" light expand="md" className="justify-content-center">
            <NavbarBrand><img height={50} src={"/Image/PMW_New_Black_Logo-Website.png"} /></NavbarBrand>
          </Navbar>
          
          <Row className="justify-content-center" noGutters>
            <Col lg={4} xs={12} className="p-2">
              
               

              <div className="shadow p-3">
                <h4 className="text-center border-bottom pb-2">{feedbackData.title}</h4>
                <h6>{feedbackData.description}</h6>
                {!!feedbackData.instruction && <div className='my-3' style={{fontSize: 14}} dangerouslySetInnerHTML={{__html: feedbackData.instruction}}></div>}
                {!!feedbackData.video_url && <video  controls={true}  autoPlay={true} width={"100%"} src={feedbackData.video_url} />}
                
                <div className="shadow-sm p-3 text-center">
                  <h6>OTP</h6>
                  <h3 className="m-0">{this.state.otp}</h3>
                </div>
            
                {!isSubmitted ? <>
                  <h6 className="mt-4 text-center">Rate our Partner</h6>
                  <Row className="justify-content-center my-3">
                    {[1, 2, 3, 4, 5].map(i =>
                    <Col xs={2}>
                      <img style={{cursor: "pointer", filter: `grayscale(${rating >= i ? 0:1})`}} height={35} width={35} onClick={() => this.setState({rating: i})} src={StarSvg} />
                    </Col>
                    )}
                  </Row>
            
                  <h6 className="mt-4">Comment: </h6>
                  <Input type="textarea" name="text" id="exampleText" value={this.state.comment} onChange={(e) => this.setState({comment : e.target.value})} />
                  <div className="text-center mt-4">
                    <Button color="danger" size="sm" onClick={this.submitFeedback}>Submit</Button>
                  </div>
                  </>
                  :
                  <>
                    <h6 className="text-center  m-5">Thank you for your valuable feedback</h6>
                    <span className="text-center d-block">For any query, you can contact us to <a href="tel:918825154594">+918825154594</a></span>
                    <h4 className="text-danger m-5 text-center">Thank You &#x1f44d;</h4>
                  </>
                }
              </div>

              <Row noGutters className="p-3 align-items-center" style={{background: "#eeeeee60"}}>
                  <Col xs={5}>
                      <img src={"/Image/utm_link_referral.png"} style={{width: "65%"}} />
                  </Col>
                  <Col xs={7} className="text-right">
                      <span className='d-block text-secondary' style={{fontWeight: 700, fontSize: 18}}>Join PickMyWork Today</span>
                      <a href={"https://pickmywork.page.link/mch-ref"} target="_blank"><Button className="my-2 px-4" size="sm" type="submit" color="danger" >Join Now</Button></a>
                      <strong className='d-block text-secondary' style={{fontSize: 12}}>Become our Trusted Partner to earn</strong>
                  </Col>
              </Row>

            </Col>
          </Row>
          
          <Loader show={this.state.loader}/>
          <GlobalErrorbar error={this.state.error} handleError={this.handleError}/>
        </>

    );
 }
}

export default Feedback;
