import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import Fade from 'reactstrap/lib/Fade';
export class Errorbar extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.props.onClose(), 4000)
		}
	}

	render() {
		// if (this.props.showBar) {
			return (
				<Fade in={this.props.showBar} unmountOnExit={true} className="error-bar">
					<p className="text-center py-2 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>{this.props.message}</p>
					<button onClick={this.props.onClose} className="close-bn"><i className="fi flaticon-cancel" style={{color: "#fa3709"}}></i></button>
				</Fade>
			)
		// }
		// else {
		// 	return null;
		// }
	}
}

class ErrorComponent extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.error.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 2500)
		}
	}

	onClose = () => {
		this.props.handleError(false)
	}

	render() {
		let { error } = this.props
		return !error.isSuccess ?
			<Fade in={!!error.showBar} unmountOnExit={true} className="error-bar">
				<p className="text-center py-2 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-alert"></i>{error.message || "Something went wrong"}</p>
				<button onClick={this.onClose} className="btn btn-link close-bn"><i className="fi flaticon-cancel" style={{color: "#fa3709"}}></i></button>
			</Fade>
			:
			<Fade in={!!error.showBar} unmountOnExit={true} className="success-bar">
				<p className="text-center py-2 r-subheading-3 mb-0 error-line d-block "><i className="fi flaticon-success"></i>{error.message || "Action Completed"}</p>
				<button onClick={this.onClose} className="btn btn-link close-bn"><i className="fi flaticon-cancel" style={{color: "#001e03"}}></i></button>
			</Fade>
		
	}
}
export const GlobalErrorbar = withRouter(ErrorComponent);

