import React from "react"
import { withRouter } from "react-router-dom";
import {auth} from "../actions/auth.js"
import {  Row, Col } from 'reactstrap';
import moment from 'moment';
import {GlobalErrorbar} from './error-bar.js';
import {Loader} from './loader.js'

class Slotselect extends React.Component {
    constructor() {
        super()
        this.state = {
          slot:"",
          bookedSlotStatus:true,
          value: null,
          slotData:[],
          selectedSlot:{},
          selectDate:'',
          activeObject:{key:1,date:"this day",day:"mon",time:"10"},
          slotdetail:[{key:1,date:"this day",day:"mon",time:"10"},{key:2,date:"this day",day:"mon",time:"10"},{key:3, date:"this day",day:"mon",time:"10"}],
          error:{showBar:false,message:""},
          loader:false
        }
       
    }

 async componentDidMount() {
    await this.init();
  }

  init = async () =>{
      if(!this.props.location.state){
          this.props.history.push('/')
      }
      else{
        const {job,user} = this.props.location.state;
        let{selectedSlot,bookedSlotStatus} = this.state;
        
            this.setState({loader:true})
            try {
                    let res = await auth.job.getSlots({job_id : job.job_id});
                    if(res.data.status==="success" && res.status===200 ){
                        this.setState({slotData:res.data.data});
                        let response = await auth.job.getbookedSlot({id:user.user_id,job_id:job.job_id});
                        if(response.data.status==="success" && response.status===200 && response.data.data[0] ){
                              selectedSlot=response.data.data[0];
                              this.setState({value:response.data.data[0].slot_id,selectedSlot,bookedSlotStatus:false});
                        }
                    }
                    this.setState({loader:false})
            }
            catch (e) {
                    this.setState({loader:false})
                    this.handleError(true,e.response.data.message)
                } }
  }

  handleError = (value,msg) =>{
      let{error} = this.state;
      error.showBar =value;
      error.message = msg;
      this.setState({error});
    }

  slotbook = async () =>{
      const {job,user} = this.props.location.state;
      let body = {
          "user_id": user.user_id,
          "slot_id": this.state.value.slot_id,
          "job_id": job.job_id,
          "booking_date": this.state.selectDate
        }
      this.setState({loader:true})
      try {
              let res = await auth.job.bookSlot(body);
              if(res.data.status==="success" && res.status===200 ){
                  this.init();
              }
              this.setState({loader:false})
      }
      catch (e) {
              this.setState({loader:false})
              this.handleError(true,e.response.data.message)
          } 
  }

    
    render(){
      let {bookedSlotStatus,slotData,selectedSlot}= this.state;

       return(
          <div>
            {bookedSlotStatus?<div>
                                 <Row className="mt-2 mb-5 justify-content-center">
                                    <h2 className="vrfHeader"> Interview Time Slots</h2>
                                 </Row>
                                 {slotData.map((el,j)=>{ return(
                                    <div key={j}>
                                      {
                                        el.slots.map((slot,i)=>{
                                             return (<Row key={i} onClick={() => {this.setState({ value: slot,selectDate:el.date })}}   className="my-3 justify-content-center rounded mx-3" style={{background:(this.state.value===slot)?"#e40000":"grey"}} >
                                                         <Col  xs={12} className="ml-3 mt-1 text-light" >
                                                          <h5>{moment(el.date).format("dddd, MMMM Do, YYYY")}</h5>
                                                         </Col>
                                                         <Col  xs={12} className="ml-3 text-light" >
                                                         <span>{moment(slot.start_time, "HH:mm:ss").format("hh:mm a")}</span></Col>
                                                      </Row>)
                                        })
                                      }
                                    </div>
                                 )
                                 })}
                                <Col xs={12} className="mt-5 text-center">
                                   <button  onClick={()=>{this.slotbook()}} className="vrfBase" >Confirm</button>     
                                </Col>
                          </div>:
                                  <div >
                                    <Row>
                                        <Col className="py-3 mt-5 text-center">
                                          <img className="py-3 mt-5 text-center tickimg" 
                                               src="./Image/check.svg" alt=""/>
                                        </Col>
                                   </Row>
                                    <Row>
                                        <Col className=" mt-4 text-center">
                                          <span>Your Slot has been booked for an interview on</span>
                                        </Col>
                                   </Row>

                                   <Row>
                                        <Col className=" mt-3 text-center">
                                           <img 
                                             src="./Image/lastimg.svg" alt=""/>
                                        </Col>
                                   </Row>
                                   {(Object.keys(selectedSlot).length>0) && <Col xs={12}>
                                                                                   <Col className=" mt-4 text-center">
                                                                                     <h5>{moment(selectedSlot.booking_date).format("dddd, MMMM Do, YYYY")}</h5>
                                                                                     <h5>{moment(selectedSlot.InterviewSlot.start_time, "HH:mm:ss").format("hh:mm a")}</h5>
                                                                                   </Col>
                                                                                   <Col className=" mt-4 text-center">
                                                                                     <h5>Interview Location</h5>
                                                                                     <h5>Netambit Infosource Private Limited, A-110, Sector 5, Near Jaguar Car Showroom, Noida</h5>
                                                                                   </Col>
                                                                              </Col>}
                                  </div>}
                       <GlobalErrorbar error={this.state.error} handleError={this.handleError}/>
                       <Loader show={this.state.loader}/>
            </div>
        )  
        
    }

}
export default withRouter(Slotselect);
