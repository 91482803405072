import React from 'react';
export const Loader = (props) => {
	return (
			<div className={"r-loader" + (props.show?"":" invisible")}>
				<div className="position-relative">
					<div className="d-flex justify-content-center">
		              <div className="spinner-border" role="status">
		                <span className="sr-only">Loading...</span>
		              </div>
		            </div>
				</div>
			</div>
	)
}

