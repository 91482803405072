import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import UserSVG from '../../../src/assets/user.svg';
import JobTask from './job_task';
import moment from "moment";

class JobTaskReportDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData : [],
            isAddIncompleteDetails: false
        };
    }


    async componentDidMount(){
        await this.fetchProjectData();
        this.fetchTaskReport();
    }

    fetchProjectData = async () => {
        let { setLoader, setError } = this.props;
        let { job_id } = this.props.data.selectedJob;
        setLoader(true);
        try{
            let body = { job_id }
            let resp = await auth.MyTeam.fetchProjectData(body);
            this.setState({taskData: resp.data.data[0]})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
    }


    fetchTaskReport = async () => {
        let { setLoader, setError } = this.props;
        let { taskData } = this.state;
        let form = taskData.formData;
        try{
            let body = {
                form_id: taskData.form_id
            }
            if(this.props.data.status === 3) body = {...body, archive_status: 1}
            else body = {...body, archive_status: 0, status: this.props.data.status}
            let resp = await auth.MyTeam.fetchProjectReportData(body);
            let data= resp.data.data;
            data = data.map(obj => {
                obj.FormResponse.FormResponseData = obj.FormResponse.FormResponseData.reduce((prev, curr) =>  (prev[curr.input_id] = curr, prev), {});
                return obj;
            });

            data = data.map(res => {
                if (form.response_status && form.response_status.status2) {
                    res.FormResponse.status2_text = form.response_status.status2[res.FormResponse.status2] ? form.response_status.status2[res.FormResponse.status2].label : "";
                }
                return res;
            })
            this.setState({reportData: data})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
    }

    addIncompleteDetails = (formResponse) => {
        this.setState({ isAddIncompleteDetails: formResponse })
    }

    render() {
        let { reportData, taskData, isAddIncompleteDetails } = this.state;
        let { teamData, data, jobName, userData } = this.props;
        let inputs = taskData && taskData.formData.input.sections[0].input_groups[0].inputs.filter(o => o.is_primary)
        if(isAddIncompleteDetails) 
            return <JobTask {...this.props} selectedJob={data.selectedJob} isAddIncompleteDetails={isAddIncompleteDetails} onClose={() => this.setState({isAddIncompleteDetails: false})} refreshCount={() => this.fetchTaskReport()} />
        return (
            <>
                <div className='position-relative text-left'>
                    <div className='position-absolute' style={{height: 140, width: "100%", background: "#eaf2f1", zIndex: "-1"}}></div>
                    <div className='p-1'>
                        <Row className="align-items-center p-3">
                            {/* <Col xs={2}><img src={teamData && teamData.profile_picture_url ? teamData.profile_picture_url : UserSVG} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                            <Col className="mt-2 text-left">
                                <strong className='d-block' style={{color: "#080808", fontSize: 18}}>{ teamData && teamData.User.first_name }</strong>
                                <span className='px-2 py-1 small' style={{background: "#699d9c", color: "#fff", borderRadius: 6}}>Team Leader</span>
                            </Col> */}
                            <Col xs={2}><img src={teamData && teamData.profile_picture_url ? teamData.profile_picture_url : UserSVG} className='' style={{width: 50, height: 50, borderRadius: "50%", border: "3px solid #fff" }} /> </Col>
                            <Col className=" text-left">
                                <strong className='d-block' style={{color: "#080808", fontSize: 18}}>{userData.first_name }</strong>
                                <span className='p-1' style={{background: "#699d9c", color: "#fff", borderRadius: 6, fontSize: 10}}>{teamData ? teamData.User.first_name : "Team Leader"}</span>
                            </Col>
                            {/* <Col xs={3} className="mt-2 text-right">
                                <a href={`tel: +91${teamData && teamData.User.mobile}`}><Button size="sm" color="light" style={{textDecoration: "none", borderRadius: "50%", height: 50, width: 50}} ><span style={{color: "#000", fontSize: 28, lineHeight: 1}}>&#9990;</span></Button></a>
                            </Col> */}
                        </Row>


                        <div className='shadow-sm mt-3  bg-light p-2 mx-3' style={{borderRadius: 12}}>
                            <Row className="align-items-center" style={{cursor: "pointer"}} onClick={() => this.props.onClose() }>
                                <Col xs={"auto"} className="pl-3 pr-0" style={{fontWeight: 800}}>&#8592;</Col>
                                <Col>
                                    <strong className='d-block' >{data.selectedJob.Employer.employer_name}</strong>
                                    <span className='small'>{data.selectedJob.JobCategory.title}</span>
                                </Col>
                                <Col xs={"auto"} className="text-right">
                                    <Button size="sm" className="py-1" outline={true} style={{borderRadius: 16, borderWidth: 0, background: "#fac240", color: "#000", fontWeight: 600, fontSize: 12}} >Add New Lead</Button>
                                </Col>
                            </Row>
                                            
                        </div>
                        <span className='d-block mt-3 small font-weight-bold'>{{0: "Added", 1: "Verified", 2: "Rejected", 3: "Expired"}[data.status]} Leads</span>
                        <div className='mt-1 border-top'>
                        {
                                reportData.length ? 
                                reportData.map(obj => {
                                    return (
                                    <div className='shadow-sm p-2 mt-2 mx-2' style={{background: "#fff", borderRadius: 8}}>
                                        <Row className="align-items-center">
                                            <Col xs={7}>
                                                {inputs.map(input => <span className='d-block'>{obj.FormResponse.FormResponseData[input.input_id] ? obj.FormResponse.FormResponseData[input.input_id].value : "--"}</span>)}
                                            </Col>
                                            <Col style={{fontSize: 14}}>
                                                <span className='small text-danger d-block'>{obj.FormResponse.status2_text}</span>
                                                <span className='small text-danger d-block'>{obj.FormResponse.status_comment}</span>
                                                <span style={{fontSize: 10}} className='d-block small'>{moment(obj.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")}</span>
                                                {data.status === 0 && obj.FormResponse.sub_status === 1 && <Button color="danger" size="sm" className="p-1" style={{fontSize: 12}} onClick={() => this.addIncompleteDetails(obj)}>+ Add Details</Button>}
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                )})
                                :
                                <em className='d-block small m-3'>Ohh! No data found</em>
                        }
                        </div>
                       
                    </div>
                        
                </div>
            </>
                
        );
        }
}

export default JobTaskReportDetails;
