
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ConfirmAction from '../../global/confirm-action';

class PartnerCustomers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            searchText: "",
            callingData: null,
            isConfirm: false
        };
    }


    componentDidMount() {
        this.fetchMappedCustomers();
    }

    componentWillUnmount() {
		clearInterval(this.interval);
		clearInterval(this.timer);
	}



    fetchMappedCustomers = async () => {
        store.dispatch(setLoader(true));
        try {
            let resp = await Auth.Partner.fetchAssignedCustomerLeads({});
            let customers = resp.data.data;
            this.setState({ customers });
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }
    
    connectCall = async (customer) => {
        store.dispatch(setLoader(true));
        try {
            let body = {
                customer_lead_id: customer.customer_lead_id,
                entity_type: "LEAD"
            }
            let resp = await Auth.Partner.connectCallCustomer(body);
            await this.setState({ callingData: resp.data.data });
            this.fetchCallEnquiry();
            this.callTimer();
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }
    
    fetchCallEnquiry = async () => {
        let { callingData } = this.state;
        if(!callingData) return null;

        try {
            let body = { call_id: callingData.Sid }
            let resp = await Auth.Partner.fetchCallEnquiry(body);
            let callDetails = resp.data.data || {};

            this.setState({ callingData: {...callDetails, Sid: callingData.Sid, timer: callingData.timer, customerData: callingData.customerData } });

            if(resp.data.data.status === "in-progress") this.interval = setTimeout(this.fetchCallEnquiry, 5000);
        }
        catch (e) {
            store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString() }))
        }
    }

    callTimer = () => {
        let { callingData } = this.state;
        if(!callingData) return null;
        callingData.timer = (callingData.timer || 0) + 1;
		this.setState({ callingData });
		if((callingData.Status || callingData.status) === "in-progress") this.interval = setTimeout(this.callTimer, 1000);
	}

    


    render() {
        let { } = this.props;
        let { customers, searchText, callingData, isConfirm } = this.state;

        if(searchText){
            customers = customers.filter(o => (o.CustomerLead.name || "").toLowerCase().includes(searchText.toLowerCase()))
        }

        return (
            <>
                {!!callingData && 
                <Row noGutter className="py-2 mx-3 mb-3 bg-warning" style={{borderRadius: 8, position: "sticky", top: 0, zIndex: 9}}>
                    <Col>
                        {<span>Calling with {callingData.customerData.name}: <b>{callingData.Status || callingData.status}</b></span>}
                    </Col>
                    <Col xs={3}>
                        { callingData.timer || 0 } Sec
                    </Col>
                    <Col xs="auto">
                        <span className='m-2' style={{cursor: "pointer"}} onClick={() => this.setState({ callingData: null })}>&#10006;</span>
                    </Col>
                </Row>
                }

                <Row >
                    <Col xs={12} md={8}>
                        <h4 className='mt-2'>Customer Leads</h4>
                    </Col>
                    <Col className="" xs={12} md={4}>
                        <InputFeild placeholder="Search" value={searchText} onChange={(e) => this.setState({ searchText: e.target.value })} />
                    </Col>
                </Row>
                <div className='p-4'>
                {
                    customers.length ? customers.map((customer, i) => 
                    <Row key={i} className="border shadow-sm my-2 p-3" style={{fontSize: 14}}>
                        <Col xs={1}>{i+1}</Col>
                        <Col xs={5}>
                            <span className='d-block'>{customer.CustomerLead.name}</span>
                            <span className='small'>{customer.CustomerLead.mobile}</span>
                        </Col>
                        <Col xs={4}>
                            <Button onClick={() => this.setState({isConfirm: () => this.connectCall(customer) })} color="link">📞</Button>
                        </Col>
                        <Col xs={2} className={"text-right"}>
                            <span className='d-block'>{moment(customer.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                            <span className='small text-secondary'>Added On</span>
                        </Col>
                    </Row>
                    )
                    :
                    <h5 className='text-center my-5 text-secondary'>No Data</h5>
                }
                </div>
                <ConfirmAction message="Are you sure to connect?" isOpen={!!isConfirm} callback={() => isConfirm()} onClose={() => this.setState({ isConfirm: false })} />
            </>

        );
    }
}

export default PartnerCustomers;
