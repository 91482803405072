import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';
import LoginVerification  from './login';
import Dashboard  from './dashboard';
import { GlobalErrorbar } from '../error-bar';
import { Loader } from '../loader';
import { TrackEvent } from '../../actions/ga4';
class Mt extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        this.state = {
            error:{showBar:false,message:""},
            isLoadingProfile: false,
            userData: null,
            teamData: null,
            loader:false,
            teamCode: null,
            checkInstallBrowserApp: null
        };
    }

    async componentDidMount(){
        this.changeFavIcon();
        var urlParams = this.props.match.params;
        let teamCode = urlParams.team_code;
        if(teamCode) localStorage.setItem('mtTeamCode', teamCode);
            else teamCode = localStorage.getItem('mtTeamCode');
        await this.setState({teamCode});
        teamCode && this.getUser(teamCode);

        
        this.prepareChromeAppInstall();
         
    }

    changeFavIcon = () => {
        document.title = "Earn with Team!"
        document.getElementById('appTitle').setAttribute('content', 'Earn with Team!');
        document.getElementById('appName').setAttribute('content', 'Earn with Team!');
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = 'data:image/x-icon;base64,AA';

        document.querySelector("meta[name='description']").content="Start work and earn with your team";

    }


    getTeamDetails = async () => {
        this.setState({loader:true});
        try{
            let resp = await auth.MyTeam.getTeam({team_code: this.state.teamCode});
            this.setState({teamData: resp.data.data && resp.data.data.team_code ? resp.data.data : null});
        }
        catch(e){
            this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({loader:false});
    }

    getUser = async () => {
        this.setState({isLoadingProfile: true});
        try{
            await this.getTeamDetails();
            let resp = await auth.MyTeam.getUser();
            this.setState({userData: resp.data.data});
        }
        catch(e){
            // this.setState({error: {showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()}});
        }
        this.setState({isLoadingProfile:false});
    }

    logout = async () => {
        this.setState({isLoadingProfile: true});
        try{
            await auth.MyTeam.logout();
            this.setState({userData: null});
        }
        catch(e){
            console.log(e)
        }
        this.setState({isLoadingProfile:false});
    }
    render() {
        let { teamCode, teamData, userData, isLoadingProfile, checkInstallBrowserApp } = this.state;

        let commonProps = {
            userData, teamCode, teamData,
            getUser: this.getUser,
            setLoader: v => this.setState({ loader: !!v }),
            setError: error => this.setState({ error }),
        }
        return (
        <>
            <div className='p-2 m-3 shadow-lg border' style={{background: "#d8ecd1", position: "absolute", zIndex: 9, top: 5, borderRadius: 12, cursor: "pointer", transform: `scale(${checkInstallBrowserApp ? 1 : 0})`, transition: "all 1s ease"}}>
                <div className='border bg-light border-secondary' style={{position: "absolute", right: -6, top: -6, height: 20, width: 20, borderRadius: "50%", cursor: "pointer"}} onClick={() => this.setState({checkInstallBrowserApp: null})}>
                    <span style={{display: "block", marginLeft: 0, marginTop: "-8px", fontSize: 22}}>&#x2717;</span>
                </div>
                <span className='d-block text-center' style={{fontWeight: 600, fontSize: 12, color: "#555"}} onClick={() => this.handleChromeAppInstall()}>Click to install MyTeamWorks Web as an mobile application to your Home Screen</span>
            </div>

            {isLoadingProfile ? 
            <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 100px)"}}>Loading...</h3>
            : 
            !teamData ? 
            <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 100px)"}}>Invalid URL</h3>
            :
            <>
                <Row noGutters className=" justify-content-center">
                    <Col lg={4} xs={12} className=" text-center">
                        {!userData ? 
                            <LoginVerification  {...commonProps} />
                            :
                            userData.blocked === 1 ? 
                            <h3 className='text-muted d-block w-100 text-center' style={{position: "absolute", top: "calc(50% - 100px)"}}>Your account has been removed, Please contact your Admin</h3>
                            :
                            <Dashboard  {...commonProps} logout={this.logout}  /> 
                        }
                    </Col>
                </Row>
            </>
            }
            <Loader show={this.state.loader}/>
            <GlobalErrorbar error={this.state.error} handleError={(v, msg) => this.setState({ error: {showBar: v, message: msg } } )}/>  
        </>
        );
    }


    prepareChromeAppInstall = () => {
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            window.checkInstallBrowserApp = event;
            setTimeout(() => { this.setState({ checkInstallBrowserApp: event }) }, 2000);
        });
        setTimeout(() => { this.setState({ checkInstallBrowserApp: null }) }, 7000);
    }
    handleChromeAppInstall = () =>  {
        if (this.state.checkInstallBrowserApp) {
            TrackEvent("MyTea_Web_App_Prompt", {})
          this.state.checkInstallBrowserApp.prompt();
          this.state.checkInstallBrowserApp.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                TrackEvent("MyTea_Web_App_Install", {})
            }
            window.checkInstallBrowserApp = null;
            this.setState({ checkInstallBrowserApp: null })
          });
        }
    }



}

export default Mt;
